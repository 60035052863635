import styled from 'styled-components';

import { ActionButton } from 'components/action-button';
import { brandFont } from 'styles/brand-font';

const ImageSizeMap = {
  small: '55px',
  medium: '60px',
  large: '70px',
  extraLarge: '80px',
};

export const Wrapper = styled.div``;

export const ImageContainer = styled.div<{ $size?: keyof typeof ImageSizeMap }>`
  font-size: 0;
  margin: 0;
  margin-inline-end: 0.75rem;
  height: ${({ $size }) => ($size ? ImageSizeMap[$size] : ImageSizeMap.medium)};
  width: ${({ $size }) => ($size ? ImageSizeMap[$size] : ImageSizeMap.medium)};
  padding: 0;

  /* for quantity badge positioning */
  position: relative;
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
`;

export const QuantityBadge = styled.div`
  position: absolute;
  background: ${p => p.theme.token('background-chip')};
  border-radius: 50%;
  height: 1.375rem;
  padding: 1.5px 5px;
  /* offset over image borders a bit */
  inset-block-start: -3px;
  inset-inline-start: -3px;
  color: white;
  text-align: center;
  line-height: 1rem;
  font-size: 0.75rem;
`;

export const ProductName = styled.label<{ isProductNameBold: boolean }>`
  font: ${({ isProductNameBold }) =>
    isProductNameBold ? brandFont.headerThree : brandFont.copyOne};
  margin: 0;
  cursor: pointer;
`;

export const ProductDescription = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin: 0;
`;

export const Layout = styled.div<{ $inline: boolean }>`
  display: flex;
  flex-direction: ${({ $inline }) => ($inline ? 'row' : 'column')};
  justify-content: ${({ $inline }) => ($inline ? 'flex-start' : 'center')};
  align-items: flex-start;
`;

export const SelectionsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

export const StyledActionButton = styled(ActionButton)`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${Styles.color.black};
  align-self: flex-start;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:active {
    color: ${Styles.color.black};
    border: none;
  }
`;
