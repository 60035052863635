import React from 'react';

import { LoadingAnimation } from 'components/loading-animation';

import { ContentLoadingContainer } from './content-loading.styled';
import { useContentLoadingState } from './use-content-loading-state';

interface ContentLoadingProps extends React.PropsWithChildren {}

export const ContentLoading: React.FC<ContentLoadingProps> = ({ children }) => {
  const { isLoading, ariaLabel } = useContentLoadingState();

  if (isLoading) {
    return (
      <ContentLoadingContainer aria-label={ariaLabel}>
        <LoadingAnimation fillColor={Styles.color.primary} />
      </ContentLoadingContainer>
    );
  }

  return <>{children}</>;
};
