import React, { Suspense, useEffect, useState } from 'react';

import { getWritingDir } from '@rbilabs/intl';
import { Helmet } from 'react-helmet-async';

import { AppHeader } from 'components/app-header';
import { BottomServiceMode } from 'components/bottom-service-mode';
import { BottomSheetRefillDrinks } from 'components/bottom-sheet-refill-drinks';
import { ErrorNetwork } from 'components/error-network';
import { Footer } from 'components/footer';
import { ForceUpdateModal } from 'components/force-update-modal';
import { LayoutBottomContainer } from 'components/layout/layout-bottom-container';
import { MobileWebNavigationBar } from 'components/mobile-web-navigation-bar';
import { ModalBeeperWrapper } from 'components/modal-beeper';
import { ModalLockersPickupWrapper } from 'components/modal-lockers-pickup';
import { SetLoading } from 'components/set-loading';
import { ToastContainer } from 'components/toast';
import { TopServiceModeNotification } from 'components/top-service-mode-notification';
import { View } from 'components/view';
import { useResetStoreResetCartResetServiceModeTimeout } from 'hooks/reset-store-timeout';
import { useBatteryLevelEvent } from 'hooks/use-battery-level-event';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useEvaluateLdFlags } from 'hooks/use-evaluate-ld-flags';
import { useForter } from 'hooks/use-forter';
import { useGeoData } from 'hooks/use-geo-data';
import { useIosAtt } from 'hooks/use-ios-att';
import { useLogPageView } from 'hooks/use-log-page-view';
import { LoyaltyOptInModal } from 'pages/loyalty/loyalty-opt-in-modal';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { FeatureFlagOverridesModal } from 'state/launchdarkly/feature-flag-overrides-modal';
import { useLocationContext } from 'state/location';
import { useScrollContext } from 'state/scroll';
import { isProduction } from 'utils/environment';
import { getIosAppTrackingStatus } from 'utils/get-ios-app-tracking-status';
import { Status } from 'utils/get-ios-app-tracking-status/types';
import * as location from 'utils/location';
import { enablePush } from 'utils/permissions';
import { routes } from 'utils/routing';

import { ContentLoading } from './content-loading';
import { ContentRouting } from './content-routing';
import { CookieBanner } from './cookie-banner-container';
import { FabContainer } from './fab-container/fab-container';
import { LayoutContextProvider } from './layout-context';
import { AppContainer, LayoutMain } from './layout.styled';
import { useAlternateLinks } from './use-alternate-links';

export const Layout = () => {
  const {
    location: { pathname },
  } = useLocationContext();
  const { scrollRef, scrollTo } = useScrollContext();
  const { language } = useLocale();

  const [canonicalUrl] = location.get('href').split('?');
  const canonicalLink = [{ rel: 'canonical', href: canonicalUrl }];
  const alternateLinks = useAlternateLinks();
  const { requestIosAttPermissions } = useIosAtt();

  const [IOSTrackingStatus, setIOSTrackingStatus] = useState<null | string>(null);

  const topServiceModeFlag = useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE);
  const bottomServiceModeFlag = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableBrazePushPrimer = useFlag(LaunchDarklyFlag.ENABLE_BRAZE_PUSH_PRIMER);

  const enableTopServiceMode = topServiceModeFlag && !bottomServiceModeFlag;
  const enableBottomServiceMode = bottomServiceModeFlag && !topServiceModeFlag;
  const showFeatureFlagOverridesModal = !isProduction || process.env.NODE_ENV === 'development';

  useBatteryLevelEvent();
  useForter();
  useLogPageView();
  useGeoData();
  useResetStoreResetCartResetServiceModeTimeout();
  useEvaluateLdFlags();

  useEffect(() => {
    scrollTo({ x: 0, y: 0, animated: false });
  }, [scrollTo, pathname]);

  async function trackingStatus() {
    const iosstatus = await getIosAppTrackingStatus();
    setIOSTrackingStatus(iosstatus);
  }

  useEffectOnce(() => {
    if (!enableBrazePushPrimer) {
      // Prompt users to enable native push notifications, the prompt is displayed only once
      enablePush();
    }
    trackingStatus();
    requestIosAttPermissions();
  });

  return (
    <>
      <Helmet link={[...canonicalLink, ...alternateLinks]}>
        <html lang={language} dir={getWritingDir(language)} />
      </Helmet>

      <LayoutContextProvider>
        <AppContainer>
          <AppHeader />

          <ContentLoading>
            <LayoutMain data-testid="main-layout" id="main" ref={scrollRef}>
              <Suspense fallback={<SetLoading />}>
                <ContentRouting />
                <Footer />
              </Suspense>
            </LayoutMain>
          </ContentLoading>

          <LoyaltyOptInModal />
          <ModalBeeperWrapper />
          <ModalLockersPickupWrapper />

          <View data-testid="footer">
            <FabContainer />
            <ToastContainer />
            <CookieBanner hasUserDeniedTracking={IOSTrackingStatus === Status.DENIED} />
            {pathname === routes.base && <BottomSheetRefillDrinks />}
            {enableTopServiceMode && <TopServiceModeNotification />}

            <LayoutBottomContainer>
              {enableBottomServiceMode && <BottomServiceMode />}
              <MobileWebNavigationBar />
            </LayoutBottomContainer>
          </View>
          <ForceUpdateModal />
          {showFeatureFlagOverridesModal && <FeatureFlagOverridesModal />}
        </AppContainer>
        <ErrorNetwork />
      </LayoutContextProvider>
    </>
  );
};
