import styled from 'styled-components';

import { View } from 'components/view';

export const AppContainer = styled(View)`
  height: 100dvh;
  width: 100vw;
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const LayoutMain = styled.main`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  /** Needed to avoid scroll issues on pages with sticky elements */
  transform: translateZ(0px);
  display: flex;
  flex-direction: column;
`;
