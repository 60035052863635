import React from 'react';

import { useIntl } from 'react-intl';

import { useLocationContext } from 'state/location';
import { useMobileHeaderBackButton } from 'state/mobile-header-nav';
import { routes } from 'utils/routing';

import { HamburgerButton } from '../hamburger-button';
import { PrimaryActionContainer } from '../styled';

interface PrimaryActionProps {
  isHamburgerMenuEnabled: boolean;
}

export const PrimaryAction: React.FC<PrimaryActionProps> = ({ isHamburgerMenuEnabled }) => {
  const { formatMessage } = useIntl();
  const {
    location: { pathname },
  } = useLocationContext();
  const BackButtonComponent = useMobileHeaderBackButton();

  const shouldShowBackButton = [
    formatMessage({ id: 'routes.linkPhysicalCard' }),
    routes.account,
    routes.aboutApp,
    `${routes.menu}/`,
    `${routes.rewardsList}/`,
    `${routes.rewardsDetails}/`,
    `${routes.rewardsOffers}/`,
  ].some(path => pathname.includes(path));

  return (
    <PrimaryActionContainer>
      {shouldShowBackButton && BackButtonComponent && <BackButtonComponent />}
      {!shouldShowBackButton && isHamburgerMenuEnabled && <HamburgerButton />}
    </PrimaryActionContainer>
  );
};
