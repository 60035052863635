import { useIntl } from 'react-intl';

import { useFeatureHomePage } from 'components/features/home-page/use-feature-home-page';
import {
  useFeatureAccountFormQuery,
  useFeatureAccountQuery,
  useFeatureMenuQuery,
} from 'generated/sanity-graphql';
import { useFeatureInvitationCode } from 'hooks/use-feature-invitation-code';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useTrendingData } from 'hooks/use-trending-data';
import { useFeaturesContext } from 'state/features';
import { useLocationContext } from 'state/location';
import { usePageManagerContext } from 'state/static-page-manager';
import { routes } from 'utils/routing';

export const useContentLoadingState = () => {
  const { formatMessage } = useIntl();
  const {
    location: { pathname },
  } = useLocationContext();
  const {
    featureAccountId = '',
    featureIdsLoading,
    featureAccountFormId = '',
    featureMenuId = '',
  } = useFeaturesContext();
  const { routesLoading } = usePageManagerContext();
  const { loading: isFeatureAccountLoading } = useFeatureAccountQuery({
    variables: { featureAccountId },
    skip: !featureAccountId || pathname !== routes.communication || pathname !== routes.orders,
  });
  const { featureNavigationLoading: isFeatureNavigationLoading } = useFeatureNavigation();
  const { loading: isTrendingContentLoading } = useTrendingData();
  const { loading: isMainMenuContentLoading } = useFeatureMenuQuery({
    variables: { featureMenuId },
    skip: !featureMenuId || pathname !== routes.menu,
  });

  const { loading: isSignUpContentLoading } = useFeatureAccountFormQuery({
    variables: { featureAccountFormId },
    skip: !featureAccountFormId || pathname !== routes.signUp || pathname !== routes.signIn,
    fetchPolicy: 'cache-and-network',
  });

  /* TODO: figure out how to get the loading status
   *       without double-triggering the Sanity call
   * CONTEXT:
   *  this code still fires off the Sanity query even
   *    though it is already fired from useFeatureHomePage hook
   *  setting skip: true means that loading always comes back false
   *  not passing anything means we still hit Sanity, but it returns
   *    an error that id is required
   *  💭 maybe moving feature home page data to context so that we
   *    have the data and loading state available multiple places?
   */
  const { featureHomePageLoading: isFeatureHomePageLoading } = useFeatureHomePage();
  const { routesLoading: isStaticRoutesLoading } = usePageManagerContext();

  const { featureInvitationCodeLoading } = useFeatureInvitationCode();

  const blockingLoadingStates = (path: string) => {
    // The ultimate goal here is to preload what the critical pages will use,
    // without holding up the user waiting for content that isn't needed on the page.
    // As we extend this pattern, more routes should be added to this switch statement.
    // This should become the only "full page spinner" in the app, so we know it when we see it.
    switch (path) {
      case routes.base:
        return [featureIdsLoading, isFeatureHomePageLoading];
      case routes.offers:
        return [featureIdsLoading];
      case routes.about:
        return [featureIdsLoading, isFeatureNavigationLoading];
      case routes.orders:
      case routes.communication:
        return [featureIdsLoading, isFeatureAccountLoading];
      case routes.menu:
        return [featureIdsLoading, isMainMenuContentLoading];
      case routes.signUp:
        return [featureIdsLoading, isSignUpContentLoading];
      case routes.trending:
        return [featureIdsLoading, isTrendingContentLoading];
      case routes.invitationCode:
        return [featureIdsLoading, featureInvitationCodeLoading];
      case routes.cateringBirthday:
      default:
        return [routesLoading, isStaticRoutesLoading];
    }
  };

  // Temporary solution, the goal is to make object for each pathname
  const getLoadingAriaLabel = (path: string) => {
    if (path === routes.menu) {
      return formatMessage({ id: 'loadingRestaurantMenu' });
    }
    return formatMessage({ id: 'loading' });
  };

  return {
    isLoading: blockingLoadingStates(pathname).some(queryIsLoading => !!queryIsLoading),
    ariaLabel: getLoadingAriaLabel(pathname),
  };
};
