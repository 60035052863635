import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ActionButton } from 'components/action-button';
import { Link } from 'components/link';
import { LoyaltyRewardsLogo } from 'pages/loyalty/loyalty-rewards-logo';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { brandFont } from 'styles/brand-font';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

const Container = styled.div`
  margin-block-start: 0;
  margin-block-end: ${primitive.$spacing10};
  margin-inline: ${primitive.$spacing3};
`;

const HaveAccount = styled.div`
  text-align: center;
  margin-block-start: ${primitive.$spacing2};
`;

const StyledRewardsLogo = styled(LoyaltyRewardsLogo)`
  margin-block-start: 0;
  margin-block-end: ${primitive.$spacing7};
  margin-inline: auto;
  padding: 0 ${primitive.$spacing6};
  width: 100%;
  display: block;
  height: 4rem;
`;

const Headline = styled.h1`
  font: ${brandFont.headerOne};
  text-align: center;
  margin-block-end: ${primitive.$spacing5};
  text-transform: ${Styles.textTransform.headlines};
`;

const StyledLink = styled(Link)`
  color: ${p => p.theme.token('text-link-default')};
  display: inline;
  font: ${brandFont.headerThree};
  text-decoration: none;
  text-transform: ${Styles.textTransform.headlines};
  margin-inline-start: ${primitive.$spacing1};

  &:hover {
    color: ${p => p.theme.token('text-link-hover')};
  }
`;

export const CreateAccount = () => {
  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();

  return (
    <Container>
      <Headline data-testid="account-sign-up-label">
        {formatMessage({
          id: loyaltyEnabled ? 'loyaltyCreateAccountHeadline' : 'createAccountHeadline',
        })}
      </Headline>
      {loyaltyEnabled && <StyledRewardsLogo data-testid="account-sign-up-loyalty-logo" />}

      <ActionButton fullWidth data-testid="account-sign-up-link" to={routes.signUp}>
        {formatMessage({ id: 'signUpNow' })}
      </ActionButton>

      <HaveAccount data-testid="account-have-account">
        {formatMessage({ id: 'alreadyHaveAnAccount' })}

        <StyledLink to={routes.signIn}>{formatMessage({ id: 'signIn' })}</StyledLink>
      </HaveAccount>
    </Container>
  );
};
