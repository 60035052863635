import React, { useCallback, useMemo, useState } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES } from 'components/bottom-service-mode/hooks/use-bottom-service-details';
import { postOrderTimeout } from 'components/bottom-service-mode/utils';
import { useSendUpdateUserAttributesEventMutation } from 'generated/graphql-gateway';
import { DeliveryStatus, RbiOrderStatus } from 'generated/rbi-graphql';
import { useDialogModal } from 'hooks/use-dialog-modal';
import { useUserOrders } from 'hooks/use-user-orders';
import { serviceModeToIntlId } from 'pages/store-locator/new-ui/store-card/hooks/use-service-mode-text';
import { useAuthContext } from 'state/auth';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { CtaButton } from '../actions/cta-button';
import { CurbsideConfirmationModal } from '../curbside-confirmation-modal';
import { ITopServiceModeDetail, TopServiceVariants } from '../types';

export const useTopServiceModeDetails = (): ITopServiceModeDetail => {
  const { serviceMode, deliveryAddress, curbsidePickupOrderId, curbsidePickupOrderTimePlaced } =
    useOrderContext();
  const { store } = useStoreContext();
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const [curbsideConfirmationModalOpen, setCurbsideConfirmationModalOpen] =
    useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { trackEvent, updateUserAttributes } = useCdpContext();
  const [sendUpdateUserAttributesEvent] = useSendUpdateUserAttributesEventMutation();
  const { isAuthenticated } = useAuthContext();
  const isAuth = isAuthenticated();

  const navigate = useNavigate();
  const [ConfirmationModal] = useDialogModal({
    showCancel: true,
    onConfirm: () => navigate(routes.serviceMode),
    modalAppearanceEventMessage: 'Service Mode/Address Confirmation',
  });

  const { data, refetch } = useUserOrders({
    variables: {
      limit: 1,
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.UPDATE_ERROR, // not a terminal status, the order could continue
        RbiOrderStatus.REFUND_SUCCESSFUL,
      ],
    },
  });

  const isCurrentDeliveryOrder = useMemo(() => {
    const deliveryOrder = data?.userOrders?.orders?.[0];
    const orderStatus = deliveryOrder?.delivery?.status;
    const failedOrderStatus = [
      DeliveryStatus.ORDER_CANCELLED,
      DeliveryStatus.ORDER_ABANDONED,
      DeliveryStatus.ORDER_DROPPED_OFF,
    ];

    if (!deliveryOrder || !orderStatus || failedOrderStatus.includes(orderStatus)) {
      return null;
    }
    return deliveryOrder;
  }, [data]);

  // @TODO: Currently we have duplicated logic for recentOrders between FAB Button, Top service Mode and Service Notification
  // We must find a way to avoid this.
  const isPreConfirmCurbside = useCallback(() => {
    return (
      curbsidePickupOrderId &&
      postOrderTimeout(
        curbsidePickupOrderTimePlaced,
        PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES
      )
    );
  }, [curbsidePickupOrderId, curbsidePickupOrderTimePlaced]);

  const handleServiceModeChange = useCallback(() => {
    trackEvent({
      name: CustomEventNames.TOP_SERVICE_MODE,
      type: EventTypes.Other,
      attributes: {
        'Service Mode': serviceMode,
      },
    });
    updateUserAttributes({ 'Service Mode': serviceMode }, {}, sendUpdateUserAttributesEvent);

    if (isPreConfirmCurbside() && isAuth) {
      return setCurbsideConfirmationModalOpen(true);
    }

    navigate(routes.serviceMode);
  }, [isAuth, isPreConfirmCurbside, trackEvent, navigate, serviceMode]);

  const handleNavigateToOrderConfirmation = (rbiOrderId: string) => {
    trackEvent({
      name: CustomEventNames.BOTTOM_SERVICE_MODE,
      type: EventTypes.Other,
      attributes: {
        'Service Mode': serviceMode,
        'Order Id': rbiOrderId,
      },
    });
    updateUserAttributes({ 'Service Mode': serviceMode }, {}, sendUpdateUserAttributesEvent);
    navigate(`${routes.orderConfirmation}/${rbiOrderId}`, {
      state: { showToast: true },
    });
  };

  const getCtaButton = () => {
    if (isPreConfirmCurbside()) {
      return (
        <CtaButton
          text="confirmArrival"
          cta={() => handleNavigateToOrderConfirmation(curbsidePickupOrderId)}
        />
      );
    } else if (isCurrentDeliveryOrder) {
      return (
        <CtaButton
          text="trackOrder"
          cta={() => handleNavigateToOrderConfirmation(isCurrentDeliveryOrder.rbiOrderId)}
        />
      );
    }
    return <CtaButton text="change" cta={handleServiceModeChange} />;
  };

  const resultBase: ITopServiceModeDetail = {
    heading: formatMessage({ id: 'forItemAvailability' }),
    details: null,
    icon: <Icon icon="location" color="icon-default" width="20px" height="20px" aria-hidden />,
    button: null,
    onTopServiceClick: handleServiceModeChange,
    variant: TopServiceVariants.DEFAULT,
    ConfirmationModal,
    CurbsideConfirmationModal,
    curbsideConfirmationModalOpen,
    setCurbsideConfirmationModalOpen,
    refetchUserOrder: refetch,
  };

  const CtaBtn = getCtaButton();

  const address = store.customerFacingAddress?.locale || store.physicalAddress?.address1;
  const isMobileOrderingEnabled = store.hasMobileOrdering && enableOrdering;

  if (store._id) {
    if (!isMobileOrderingEnabled && address) {
      return {
        ...resultBase,
        button: CtaBtn,
        heading: formatMessage({ id: 'yourSelectedStore' }),
        details: address,
        icon: (
          <Icon icon="restaurant" color="icon-default" width="20px" height="20px" aria-hidden />
        ),
      };
    }
    switch (serviceMode) {
      case ServiceMode.DELIVERY:
        if (deliveryAddress) {
          return {
            ...resultBase,
            button: CtaBtn,
            heading: formatMessage({ id: 'delivery' }),
            details: deliveryAddress.addressLine1,
            icon: (
              <Icon icon="delivery" color="icon-default" width="20px" height="20px" aria-hidden />
            ),
          };
        }
        break;
      case ServiceMode.CURBSIDE:
        if (address) {
          return {
            ...resultBase,
            button: CtaBtn,
            heading: formatMessage({ id: 'curbside' }),
            details: address,
            icon: (
              <Icon icon="restaurant" color="icon-default" width="20px" height="20px" aria-hidden />
            ),
          };
        }
        break;
      case ServiceMode.TAKEOUT:
      case ServiceMode.DRIVE_THRU:
      case ServiceMode.EAT_IN:
      case ServiceMode.TABLE_SERVICE:
        if (address) {
          return {
            ...resultBase,
            button: CtaBtn,
            heading: formatMessage({ id: serviceModeToIntlId[serviceMode as ServiceMode] }),
            details: address,
            icon: (
              <Icon icon="restaurant" color="icon-default" width="20px" height="20px" aria-hidden />
            ),
          };
        }
        break;
      default:
    }
  }
  return {
    ...resultBase,
    variant: TopServiceVariants.NO_STORE,
  };
};
