const amplitudePluginUrl =
  'https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.4.0-min.js.gz';

export const getSessionReplayPlugin = () => {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = amplitudePluginUrl;
    script.onload = resolve;
    script.onerror = reject;
    head.appendChild(script);
  });
};
