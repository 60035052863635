import styled from 'styled-components';

import { brandFont } from 'styles/brand-font';
import { primitive } from 'styles/constants/primitives';

export const ReceiptHeadInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReceiptInfoLabel = styled.p`
  font: ${brandFont.copyOne};
  margin-block: 0;
  margin-inline-start: 0;
  margin-inline-end: ${primitive.$spacing2};
`;

export const ReceiptInfoDetail = styled.div`
  display: flex;
  flex-direction: column;
  font: ${brandFont.copyOne};
`;

export const ReceiptInfo = styled.div`
  display: flex;
`;

export const Title = styled.h4`
  margin: 0;
  font: ${brandFont.headerThree};
  color: ${p => p.theme.token('text-default')};
`;

export const ReceiptDate = styled(ReceiptInfoLabel)``;

export const ReceiptInfoDetailLabel = styled.p`
  margin: 0;
`;
