import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IconLanguage } from 'components/icons/language';
import { LanguageSelectorModal } from 'components/language-selector-modal';
import { TextButton } from 'components/text-button';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export interface OptionalColorProps {
  color?: string;
}

const StyledTextButton = styled(TextButton)<OptionalColorProps>`
  display: flex;
  color: ${p => p.color};
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  font-size: 0.9375rem;
  align-items: center;
  gap: 0.25rem;
`;

const StyledLanguageIcon = styled(IconLanguage)`
  width: 1.2rem;
`;

export const LanguageSelector: React.FC<OptionalColorProps> = ({ color = Styles.color.white }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const [showLanguageSelectorModal, setShowLanguageSelectorModal] = useState(false);

  const onModalDismiss = () => {
    setShowLanguageSelectorModal(false);
  };

  const onModalShow = () => {
    setShowLanguageSelectorModal(true);
  };

  return isLocalizationDisabled ? null : (
    <>
      <StyledTextButton
        aria-label={formatMessage({ id: 'languageSelector' })}
        data-testid="language-selector-button"
        onClick={onModalShow}
        color={color}
      >
        <StyledLanguageIcon fill={color} aria-hidden="true" />
        <span lang={language} data-testid="active-language">
          {language}
        </span>
      </StyledTextButton>
      {showLanguageSelectorModal && <LanguageSelectorModal onModalDismiss={onModalDismiss} />}
    </>
  );
};
