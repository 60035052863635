import React from 'react';

import { Route, RouteObject, RoutesProps, useRoutes } from 'react-router';

import { getMetaRobotsContent } from './utils';
import { WithMetaRobotsRouteWrapper } from './with-meta-robots-route-wrapper';

export function createRoutesFromChildren(
  children: React.ReactNode,
  parentPath: number[] = []
): RouteObject[] {
  let routes: RouteObject[] = [];

  React.Children.forEach(children, (element, index) => {
    if (!React.isValidElement(element)) {
      // Ignore non-elements. This allows people to more easily inline
      // conditionals in their route config.
      return;
    }

    let treePath = [...parentPath, index];

    if (element.type === React.Fragment) {
      // Transparently support React.Fragment and its children.
      routes.push.apply(routes, createRoutesFromChildren(element.props.children, treePath));
      return;
    }

    if (element.props.index && element.props.children) {
      return;
    }

    if (element.type === Route) {
      const metaRobotsContent = getMetaRobotsContent(element.props);
      const routeElement = metaRobotsContent ? (
        <WithMetaRobotsRouteWrapper content={metaRobotsContent}>
          {element.props.element}
        </WithMetaRobotsRouteWrapper>
      ) : (
        element.props.element
      );
      const route: RouteObject = {
        ...element.props,
        id: element.props.id || treePath.join('-'),
        element: routeElement,
        hasErrorBoundary: element.props.ErrorBoundary != null || element.props.errorElement != null,
      };

      if (element.props.children) {
        route.children = createRoutesFromChildren(element.props.children, treePath);
      }

      routes.push(route);
    }
  });

  return routes;
}

export const Routes: React.FC<RoutesProps> = ({ children, location }) =>
  useRoutes(createRoutesFromChildren(children), location);
