export const relativeRoutes = {
  address: 'address',
  addCard: 'add-card',
  addExtra: 'extras',
  addGiftCard: 'add-gift-card',
  addPaymentMethod: 'add-payment-method',
  paypalModal: 'paypal-modal',
  favorites: 'favorites',
  transferGiftCard: 'transfer-gift-card',
  locationService: 'location-service',
  noDeliveryStores: 'no-delivery-stores',
  deliveryStoresClosed: 'delivery-stores-closed',
  orderPayment: 'payment',
  pickupMode: 'pickup-mode',
  recentItems: 'recent-items',
  serviceMode: 'service-mode',
  store: 'store',
  storeLocator: 'store-locator',
  sharedRolls: 'send',
  successGiftCard: 'success-gift-card',
  successTransferGiftCard: 'success-transfer-gift-card',
  waiting: 'waiting',
};

export const routes = {
  about: '/about',
  aboutApp: '/about-app',
  account: '/account',
  addCard: `/account/payment/${relativeRoutes.addCard}`,
  addGiftCard: `/account/payment/${relativeRoutes.addGiftCard}`,
  address: `/store-locator/${relativeRoutes.address}`,
  authChallengeJwt: '/auth-challenge-jwt',
  base: '/',
  browseMenu: '/browse-menu',
  bkPay: '/bkpay',
  bkPayPaymentRequest: '/payment-request',
  bkRewards: '/bkrewards',
  blueLightCard: '/account/memberships/details/blue-light-card',
  cart: '/cart',
  cartAddCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.addCard}`,
  cartAddPaymentMethod: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.addPaymentMethod}`,
  cartTransferGiftCard: `/cart/${relativeRoutes.transferGiftCard}`,
  cartTransferGiftCardPayment: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.transferGiftCard}`,
  cardAddGiftCard: `/cart/${relativeRoutes.addGiftCard}`,
  cardAddGiftCardPayment: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.addGiftCard}`,
  paypalModal: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.paypalModal}`,
  cartPayment: `/cart/${relativeRoutes.orderPayment}`,
  cartPaymentSuccessGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.successGiftCard}`,
  cartPaymentTransferSuccessGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.successTransferGiftCard}`,
  cartSuccessGiftCard: `/cart/${relativeRoutes.successGiftCard}`,
  cartTransferSuccessGiftCard: `/cart/${relativeRoutes.successTransferGiftCard}`,
  cateringBirthday: '/catering-birthday',
  communication: `/communication-preferences`,
  confirmJwt: '/confirm-jwt',
  confirmOtp: '/confirm-otp',
  delivery: '/delivery',
  deliveryStoresClosed: `/store-locator/${relativeRoutes.deliveryStoresClosed}`,
  diagnostics: '/diagnostics',
  exploreMenu: '/explore-menu',
  favorites: `/menu/${relativeRoutes.favorites}`,
  favoriteEditor: `/menu/${relativeRoutes.favorites}/:favoriteId/edit`,
  foundation: '/foundation',
  info: '/account/info',
  invitationCode: '/account/invitation-code',
  loading: '/loading',
  locationService: `/store-locator/${relativeRoutes.locationService}`,
  menu: '/menu',
  membershipDetails: '/account/memberships/details',
  missingItems: '/missing-items',
  missingOrder: '/missing-order',
  noDeliveryStores: `/store-locator/${relativeRoutes.noDeliveryStores}`,
  notFound: '/not-found',
  notFound404: '/not-found/404',
  nutritionExplorer: '/nutrition-explorer',
  offers: '/offers',
  orderConfirmation: '/order-confirmation',
  orderProcessing: '/order-processing',
  orderPayment: '/order-payment',
  orders: '/account/orders',
  payment: '/account/payment',
  accountPaymentSuccessGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.successGiftCard}`,
  accountPaymentTransferSuccessGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.successTransferGiftCard}`,
  pickupMode: `/store-locator/${relativeRoutes.pickupMode}`,
  redeem: '/redeem',
  reportOrderIssue: '/digitalorderhelp',
  rewardsList: '/rewards/list',
  rewardsDetails: '/rewards/details',
  rewardsOffers: '/rewards/offers',
  rewardsHistory: '/rewards/history',
  scaryPlaces: '/scaryplaces',
  serviceMode: `/store-locator/${relativeRoutes.serviceMode}`,
  settings: `/account/settings`,
  settingsLocation: `/account/settings/location`,
  signIn: '/signin',
  signOut: '/signout',
  signUp: '/signup',
  store: `/store-locator/${relativeRoutes.store}`,
  storeLocator: '/store-locator',
  support: '/support',
  supportCategories: '/support/categories',
  supportCategory: '/support/categories/:categoryId',
  supportOrders: '/support/orders',
  supportForm: '/support/form',
  transferGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.transferGiftCard}`,
  trending: '/trending',
  userPreferencesModal: '/user-preferences-modal',
  contactUs: '/contact-us',
  offerActivate: '/activate-offer',
  createAccount: '/create-account',
  waitingPayment: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.waiting}`,
};
