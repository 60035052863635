import { LoyaltyTierKey } from 'generated/graphql-gateway';
import { useFeatureLoyaltyRewardListsQuery } from 'generated/sanity-graphql';

export const useFeatureLoyaltyTiersRewardList = (userTier?: LoyaltyTierKey) => {
  const { data, loading } = useFeatureLoyaltyRewardListsQuery({
    variables: {
      where: {
        rewardListLoyaltyTier: { eq: userTier },
      },
    },
    skip: !userTier,
  });

  return {
    data: data?.allRewardList[0],
    loading,
  };
};
