import React, { useCallback } from 'react';

import { ActionButtonSizes } from 'components/action-button';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

import { StyledButton, StyledMyCodeIcon } from './styled';

export const MyCodeButton = () => {
  const { navigate, location } = useLocationContext();
  const cdp = useCdpContext();

  const handleClick = useCallback(() => {
    cdp.trackEvent({
      name: CustomEventNames.BUTTON_CLICK,
      type: EventTypes.Navigation,
      attributes: {
        Name: 'My Code Header',
      },
    });

    navigate(routes.redeem, {
      state: {
        backRoute: location.pathname,
      },
    });
  }, [location.pathname, cdp, navigate]);

  return (
    <StyledButton
      data-testid="my-code-header-button"
      onClick={handleClick}
      size={ActionButtonSizes.SMALL}
    >
      <StyledMyCodeIcon />
    </StyledButton>
  );
};
