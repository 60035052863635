import React from 'react';

import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { Route, useMatch } from 'react-router-dom';

import { Routes } from 'components/routes';
import { useAuthRedirects } from 'hooks/auth/use-auth-redirects';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useFeatureInvitationCode } from 'hooks/use-feature-invitation-code';
import { useFeatureMemberships } from 'hooks/use-feature-memberships';
import { useForceWaitingPaymentRedirects } from 'hooks/use-force-waiting-payment-redirects/use-force-waiting-payment-redirects';
import { useBluecodeContext } from 'state/bluecode/bluecode';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useOrderContext } from 'state/order';
import { isFalse } from 'utils/index';
import { askPermissionOnCritialRoute, getShouldReloadApp } from 'utils/live-updates';
import { routes } from 'utils/routing';

import { LazyRoutes, brandRoutes, renderBrandRoutes } from './routes';

export const ContentRouting = () => {
  const { formatMessage } = useIntl();
  const isBaseRoute = useMatch(routes.base);

  const { clearServerOrder } = useOrderContext(); // @todo type order context
  const { isBluecodeEnabled } = useBluecodeContext();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { enableInvitationCode } = useFeatureInvitationCode();
  const { enableMemberships } = useFeatureMemberships();
  const { claimPointsEnabled, loyaltySurpriseOffersEnabled } = useLoyaltyContext();

  const enableOrderSupport = useFlag(LaunchDarklyFlag.ENABLE_ORDER_SUPPORT);
  const enableAccountDeletePage = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_DELETE_PAGE);
  const enableRequestMyInformation = useFlag(LaunchDarklyFlag.ENABLE_REQUEST_MY_INFORMATION);
  const enableCommPrefFlag = useFlag(LaunchDarklyFlag.ENABLE_COMMUNICATION_PREFERENCES);
  const enableCommunicationPreferences = !isFalse(enableCommPrefFlag);
  const isUnifiedSignInEnabled = useFlag(LaunchDarklyFlag.ENABLE_UNIFIED_SIGN_IN);
  const enableGuestAndMultiStepSupportForm = useFlag(
    LaunchDarklyFlag.ENABLE_GUEST_AND_MULTI_STEP_SUPPORT_PAGE
  );
  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;
  const forceAuthOnHomepageFlag = useFlag(LaunchDarklyFlag.FORCE_HOMEPAGE_AUTH);
  const enableOffersStaticPage = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_STATIC_PAGE);
  const enableAccountSettings = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_SETTINGS);
  const enableGeolocation = useFlag(LaunchDarklyFlag.ENABLE_SETTINGS_GEOLOCATION);
  const enableCateringBirthday = useFlag(LaunchDarklyFlag.ENABLE_CATERING_BIRTHDAY);

  const forceAuthOnHomepage = forceAuthOnHomepageFlag && enableSignUp && isBaseRoute;
  const permissionToUpdate = askPermissionOnCritialRoute() && getShouldReloadApp();

  useAuthRedirects({ skip: !forceAuthOnHomepage });
  useForceWaitingPaymentRedirects();

  useEffectOnce(() => {
    // reset the server order when a user navigate into the menu no matter what
    clearServerOrder();
  });

  const SignUpComponent = isUnifiedSignInEnabled ? (
    <LazyRoutes.SignUpUnified />
  ) : (
    <LazyRoutes.SignupHome />
  );

  return (
    <Routes>
      <Route path={'*'} element={<LazyRoutes.StaticPage />} />
      <Route noIndex path={routes.notFound404} element={<LazyRoutes.NotFound />} />
      <Route noIndex path={routes.confirmJwt} element={<LazyRoutes.ConfirmJwt />} />
      {loyaltyEnabled && (
        <>
          <Route
            path={`${formatMessage({ id: 'routes.loyaltyDashboard' })}`}
            element={<LazyRoutes.LoyaltyRewardsMemo />}
          />
          <Route
            path={`${formatMessage({ id: 'routes.loyaltyDashboard' })}/*`}
            element={<LazyRoutes.LoyaltyRewardsMemo />}
          />
        </>
      )}
      {loyaltySurpriseOffersEnabled && (
        <Route
          noIndex
          path={`${formatMessage({ id: 'routes.loyaltyHome' })}/${formatMessage({
            id: 'surprise',
          })}`}
          element={<LazyRoutes.LoyaltySurprise />}
        />
      )}
      <Route
        noIndex
        path={formatMessage({ id: 'routes.redemptionInRestaurant' })}
        element={<LazyRoutes.LoyaltyInRestaurantRedemption />}
      />
      <Route
        noIndex
        path={formatMessage({ id: 'routes.redeem-is-not-available-at-this-restaurant' })}
        element={<LazyRoutes.LoyaltyNotAvailableAtThisRestaurant />}
      />
      <Route
        noIndex
        path={formatMessage({ id: 'routes.redeem-is-not-available-at-delivery-mode' })}
        element={<LazyRoutes.LoyaltyNotAvailableAtDeliveryMode />}
      />
      <Route
        noIndex
        path={formatMessage({ id: 'routes.linkPhysicalCard' })}
        element={<LazyRoutes.LoyaltyLinkCard />}
      />
      {claimPointsEnabled && (
        <Route
          noIndex
          path={`${formatMessage({ id: 'routes.claimPointsBase' })}/*`}
          element={<LazyRoutes.LoyaltyClaimPoints />}
        />
      )}
      {enableSignUp && (
        <>
          <Route path={routes.signUp} element={SignUpComponent} />
          <Route path={routes.signIn} element={SignUpComponent} />
          <Route noIndex path={routes.confirmOtp} element={SignUpComponent} />
          <Route path={routes.createAccount} element={SignUpComponent} />
          <Route noIndex path={routes.account} element={<LazyRoutes.Account />} />
        </>
      )}
      <Route
        noIndex
        path={routes.authChallengeJwt}
        element={<LazyRoutes.AuthJwtChallengeScreen />}
      />
      <Route noIndex path={routes.signOut} element={<LazyRoutes.SignOut />} />
      <Route path={routes.menu} element={<LazyRoutes.MainMenu />} />
      <Route path={routes.browseMenu} element={<LazyRoutes.MainMenu />} />

      {/*
        MenuContent handles the following routes:

        /menu/favorites
        /menu/recent-items
        /menu/section-:id
        /menu/picker-:id
        /menu/combo-:id
        /menu/item-:id
        /menu/* (Item Not Found)

      */}
      <Route path={`${routes.menu}/:id`} element={<LazyRoutes.MenuContent />} />
      <Route path={`${routes.browseMenu}/:id`} element={<LazyRoutes.MenuContent />} />

      <Route path={routes.base} element={<LazyRoutes.Home />} />
      {!enableOffersStaticPage && (
        <Route path={`${routes.offers}/*`} element={<LazyRoutes.OffersPage />} />
      )}
      <Route
        path={formatMessage({ id: 'routes.loyaltyHome' })}
        element={<LazyRoutes.LoyaltyRewardsMemo />}
      />

      <Route
        path={formatMessage({ id: 'routes.loyaltyOfferList' })}
        element={<LazyRoutes.LoyaltyOffers />}
      />
      <Route
        path={`${formatMessage({ id: 'routes.loyaltyOfferList' })}/*`}
        element={<LazyRoutes.LoyaltyOffers />}
      />
      <Route
        path={formatMessage({ id: 'routes.loyaltyRewardList' })}
        element={<LazyRoutes.LoyaltyRewardsMemo />}
      />
      <Route
        path={`${formatMessage({ id: 'routes.loyaltyRewardList' })}/*`}
        element={<LazyRoutes.LoyaltyRewardsMemo />}
      />

      <Route noIndex path={routes.info} element={<LazyRoutes.AccountInfo />} />
      <Route
        noIndex
        path={`${formatMessage({ id: 'routes.rewardsHistory' })}`}
        element={<LazyRoutes.History />}
      />
      {enableCommunicationPreferences && (
        <Route noIndex path={routes.communication} element={<LazyRoutes.CommPreferences />} />
      )}
      <Route noIndex path={`${routes.orders}/*`} element={<LazyRoutes.AccountOrders />} />
      {enableAccountDeletePage && (
        <Route
          noIndex
          path={formatMessage({ id: 'routes.accountDelete' })}
          element={<LazyRoutes.AccountDelete />}
        />
      )}
      {enableRequestMyInformation && (
        <Route
          noIndex
          path={formatMessage({ id: 'routes.accountRequestInfo' })}
          element={<LazyRoutes.AccountRequestInfo />}
        />
      )}
      <Route noIndex path={`${routes.payment}/*`} element={<LazyRoutes.PaymentMethods />} />
      <Route path={routes.about} element={<LazyRoutes.AboutPage />} />
      <Route noIndex path={routes.aboutApp} element={<LazyRoutes.AboutApp />} />
      <Route noIndex path={`${routes.diagnostics}/*`} element={<LazyRoutes.Diagnostics />} />
      {enableOrderSupport && (
        <>
          <Route
            noIndex
            path={`${routes.reportOrderIssue}/:orderId`}
            element={<LazyRoutes.ReportOrderIssuePage />}
          />
          <Route
            noIndex
            path={`${routes.missingOrder}/:orderId`}
            element={<LazyRoutes.ReportOrderNeverArrivedPage />}
          />
          <Route
            noIndex
            path={`${routes.missingItems}/:orderId`}
            element={<LazyRoutes.ReportOrderItemsMissingPage />}
          />
        </>
      )}
      {enableInvitationCode && (
        <Route noIndex path={`${routes.invitationCode}`} element={<LazyRoutes.InvitationCode />} />
      )}
      {enableGuestAndMultiStepSupportForm ? (
        <>
          {/* // general support requests */}
          <Route path={routes.supportForm} element={<LazyRoutes.SupportV2FormPage />} />
          <Route path={routes.supportOrders} element={<LazyRoutes.SupportV2OrdersListPage />} />
          <Route path={routes.supportCategories} element={<LazyRoutes.SupportV2CategoriesPage />} />
          <Route path={routes.supportCategory} element={<LazyRoutes.SupportV2CategoryPage />} />
          <Route path={routes.support} element={<LazyRoutes.SupportV2CategoriesPage />} />
        </>
      ) : (
        <Route path={routes.support} element={<LazyRoutes.SupportPage />} />
      )}

      <Route noIndex path={routes.favoriteEditor} element={<LazyRoutes.FavoriteEditor />} />

      {/* We only want brand-specific routes to be available for those brands */}
      {/* `brandRoutes` can be thought of a Map of routes (string) to Components (React component) */}
      {renderBrandRoutes(brandRoutes, formatMessage)}

      {enableAccountSettings && (
        <Route noIndex path={routes.settings} element={<LazyRoutes.AccountSettings />} />
      )}

      {enableMemberships && (
        <>
          <Route
            noIndex
            path={formatMessage({ id: 'routes.memberships' })}
            element={<LazyRoutes.Memberships />}
          />
          <Route
            noIndex
            path={formatMessage({ id: 'routes.membershipDetails' })}
            element={<LazyRoutes.MembershipDetails />}
          />
          <Route
            noIndex
            path={formatMessage({ id: 'routes.blueLightCard' })}
            element={<LazyRoutes.BlueLightCard />}
          />
        </>
      )}

      {enableGeolocation && (
        <Route noIndex path={routes.settingsLocation} element={<LazyRoutes.SettingsLocation />} />
      )}
      {isBluecodeEnabled && (
        <>
          <Route noIndex path={routes.bkPay} element={<LazyRoutes.BkPay />} />
          <Route noIndex path={routes.bkPayPaymentRequest} element={<LazyRoutes.Home />} />
        </>
      )}
      {/** No desktop footer */}
      <Route noIndex path={routes.loading} element={<LazyRoutes.LoadingPage />} />
      <Route
        noIndex
        path={routes.cartAddPaymentMethod}
        element={<LazyRoutes.PaymentMethodPage />}
      />
      <Route path={`${routes.storeLocator}/*`} element={<LazyRoutes.StoreLocator />} />
      <Route
        path={`${formatMessage({ id: 'routes.storeLocator' })}/*`}
        element={<LazyRoutes.StoreLocator />}
      />

      <Route noIndex path={routes.waitingPayment} element={<LazyRoutes.WaitingPaymentPage />} />
      <Route
        noIndex
        path={`${routes.cart}/*`}
        element={<LazyRoutes.Cart permissionToUpdate={permissionToUpdate} />}
      />

      {enableCateringBirthday && (
        <Route
          noIndex
          path={routes.cateringBirthday}
          element={<LazyRoutes.CateringBirthdaySection />}
        />
      )}

      <Route
        noIndex
        path={`${routes.orderConfirmation}/:rbiOrderId`}
        element={<LazyRoutes.OrderConfirmation />}
      />
      <Route
        noIndex
        path={`${routes.orderProcessing}/:rbiOrderId`}
        element={<LazyRoutes.OrderProcessing />}
      />
      <Route
        noIndex
        path={`${routes.orderPayment}/:rbiOrderId?`}
        element={<LazyRoutes.PaymentPage />}
      />
    </Routes>
  );
};
