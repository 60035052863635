import React, { useCallback } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';

import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { useLocationContext } from 'state/location';

import { LinkNoDecoration } from './styled';

export const HamburgerButton: React.FC = () => {
  const { featureNavigationMobileHeaderButton } = useFeatureNavigation();
  const cdp = useCdpContext();
  const {
    location: { pathname },
  } = useLocationContext();
  const navigationLink = featureNavigationMobileHeaderButton?.link?.locale;

  const handleClick = useCallback(() => {
    cdp.logNavigationClick(CustomEventNames.BUTTON_CLICK, {
      Name: 'Account',
      Path: pathname,
    });
  }, [cdp, pathname]);

  return navigationLink ? (
    <LinkNoDecoration to={navigationLink} onClick={handleClick} data-testid="hamburger-button">
      <Icon icon="menu" color="icon-header-contrast" width="1.5rem" aria-hidden />
    </LinkNoDecoration>
  ) : null;
};
