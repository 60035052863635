import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { styled } from 'styled-components';

import { useUIContext } from 'state/ui';
import { centsToDollarString } from 'utils';

export interface ICurrencyProps {
  'data-testid'?: string;
  isLoading?: boolean;
  amount?: number;
  prefix?: string;
  suffix?: string;
  hideCurrencySymbol?: boolean;
  id?: string;
  className?: any;
}

const StyledSkeleton = styled(Skeleton)`
  &::after {
    width: 100px;
  }
`;

export const Currency = ({
  'data-testid': testId,
  amount = 0,
  prefix = '',
  suffix = '',
  hideCurrencySymbol = false,
  className,
  isLoading,
}: ICurrencyProps) => {
  const { formatCurrencyForLocale } = useUIContext();
  const formattedAmount = hideCurrencySymbol
    ? centsToDollarString(amount)
    : formatCurrencyForLocale(amount);

  return (
    <SkeletonTheme baseColor={Styles.color.grey.four}>
      <div
        data-testid={testId}
        aria-label={`${prefix}${formattedAmount}${suffix}`}
        className={className}
      >
        {isLoading ? (
          <StyledSkeleton width={15} height={40} />
        ) : (
          <>
            {prefix}
            {formattedAmount}
            {suffix}
          </>
        )}
      </div>
    </SkeletonTheme>
  );
};
