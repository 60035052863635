import React from 'react';

import { InverseButton as Button } from '@rbilabs/components-library/build/components/button';
import styled from 'styled-components';

type ButtonProps = React.ComponentProps<typeof Button>;

type StoreCardButtonProps = Pick<ButtonProps, 'aria-label' | 'color' | 'startIcon' | 'onClick'>;

const StyledButton = styled(Button)`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.125s ease;

  &:hover {
    background: #dcdcdc;
    transition: all 0.125s ease;
  }

  &:active {
    box-shadow: 0 0 0 0.25rem #cecece;
  }
`;

export const StoreCardButton: React.FC<StoreCardButtonProps> = ({
  'aria-label': ariaLabel,
  color,
  onClick,
  startIcon,
}) => {
  return (
    <StyledButton
      aria-label={ariaLabel}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      transparent
    />
  );
};
