import styled, { css, keyframes } from 'styled-components';

import { ActionButton } from 'components/action-button';
import { IconX } from 'components/icons/x';
import { MarketingBasicTile } from 'components/marketing-basic-tile';
import { BasicTileTitle } from 'components/marketing-basic-tile/styled';
import { brandFont } from 'styles/brand-font';
import { fadeOut, slideIn } from 'utils/style/animations';

const FADE_OUT_ANIMATION_DURATION_SECONDS = 0.5;
const REDUCE_SIZE_ANIMATION_DURATION_SECONDS = 0.5;
export const ENTER_ANIMATION_DURATION_SECONDS = 0.5;
export const EXIT_ANIMATION_DURATION_SECONDS =
  FADE_OUT_ANIMATION_DURATION_SECONDS + REDUCE_SIZE_ANIMATION_DURATION_SECONDS;

const reduceSize = keyframes`
  to {
    height: 0;
    border: 0;
  }
`;

export const InRestaurantRedemptionEntryContainer = styled.li<{
  $elementHeight: number;
}>`
  animation: ${slideIn} ${ENTER_ANIMATION_DURATION_SECONDS}s none;

  &.exit {
    ${({ $elementHeight }) => css`
      height: ${$elementHeight}px;
      animation:
        ${fadeOut} ${FADE_OUT_ANIMATION_DURATION_SECONDS}s forwards,
        ${reduceSize} ${REDUCE_SIZE_ANIMATION_DURATION_SECONDS}s forwards
          ${FADE_OUT_ANIMATION_DURATION_SECONDS}s;
    `}
  }

  display: flex;
  list-style: none;
`;

export const InRestaurantRedemptionEntryContent = styled.div`
  display: flex;
  width: 100%;
  padding-block-start: 0.8125rem;
  padding-block-end: 0.8125rem;
  padding-inline: 0.625rem;
`;

export const InRestaurantRedemptionList = styled.ul`
  padding: 0;
  width: 100%;
  margin: 0;

  ${InRestaurantRedemptionEntryContainer} {
    border-block-start: solid 1px ${Styles.color.grey.five};
  }

  ${InRestaurantRedemptionEntryContainer}:last-child {
    border-block-end: solid 1px ${Styles.color.grey.five};
    margin-block-end: 1rem;
  }
`;

export const RewardDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-inline-start: 1rem;
  margin-inline-end: 0.5rem;
`;

export const ViewDetails = styled(ActionButton)`
  color: ${Styles.color.black};
  font-family: ${Styles.fontFamily.body};
  text-align: start;
  font-size: 0.9rem;
  font-weight: 300;
`;

export const WrapperName = styled.div`
  display: flex;
  flex-direction: column;
  margin-block-end: 0.5rem;
`;

export const StyledIconX = styled(IconX)`
  fill: ${Styles.color.black};
  max-width: 0.875rem;
  max-height: 0.875rem;
  width: 100%;
  height: 100%;
  margin-inline-start: auto;
`;

export const EmptyState = styled.div`
  display: inline-flex;
  padding: 16px;
  align-items: center;
  font-size: 0.875rem;
`;

export const EmptyStateText = styled.p`
  color: ${Styles.color.primary};
  text-decoration: underline;
  margin: 0 4px;
`;

export const EmptyCartWidgetStyled = styled(MarketingBasicTile)`
  margin: 0.5rem 1rem;
  padding: 1rem;
  background-color: ${Styles.color.white};
  border-radius: ${Styles.borderRadius};

  ${BasicTileTitle} {
    color: ${Styles.color.black};
    font-size: 1.125rem;
    line-height: 1em;
    text-align: center;
  }

  // Styles for TypographyBlock
  p {
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    color: ${Styles.color.black};
    text-align: center;
    font-size: 1rem;
    line-height: 1.375em;
  }
`;

export const PictureAndDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const EmptyStateV3 = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-block: 1rem;
  padding-inline: 1.5rem;

  ${Styles.mobileTiny} {
    flex-wrap: wrap;
  }

  ${Styles.desktop} {
    padding-inline: 0;
  }
`;

export const AddButton = styled(ActionButton)`
  flex: 1;
  font-size: 0.85rem;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  max-width: fit-content;

  ${Styles.mobileTiny} {
    flex: unset;
    width: 100%;
    margin-block-end: 0.3rem;
    justify-content: center;
  }

  ${Styles.desktop} {
    justify-content: center;
  }
`;

export const YourOrderTitle = styled.div`
  font: ${brandFont.headerThree};
  font-weight: normal;
  font-size: 1.125rem;
  padding-block-start: 0.5rem;
  padding-block-end: 1rem;
  padding-inline: 1rem;
`;

export const YourOrderDescription = styled.div`
  text-align: center;
  font-size: 0.75rem;
  padding-block: 1.25rem;
  padding-inline: 1rem;
`;
