import { IPaymentMethod } from './types';

export const CASH_ACCOUNT_IDENTIFIER = 'CASH';
export const FREE_ORDER_ACCOUNT_IDENTIFIER = 'FREE_ORDER';

export const DEFAULT_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  accountIdentifier: '',
  fdAccountId: '',
  paymentMethodBrand: null,
  chaseProfileId: null,
  paypalIdentifier: null,
  credit: null,
  prepaid: null,
  cash: null,
  paypal: null,
  ideal: null,
  blik: null,
  sodexo: null,
  chequeGourmet: null,
  ticketRestaurant: null,
  paymentOnDeliveryCard: null,
  transient: false,
  bizum: null,
  waylet: null,
  applePayLink: null,
  mbway: null,
  onlinePayment: true,
};

export const PAYPAL_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  paypal: true,
  fdAccountId: 'PAYPAL',
  accountIdentifier: 'PAYPAL',
  transient: true,
  onlinePayment: true,
};

export const BLIK_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  fdAccountId: 'BLIK',
  accountIdentifier: 'BLIK',
  paymentMethodBrand: 'BLIK',
  blik: true,
  onlinePayment: true,
};

export const SODEXO_VOUCHER_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  sodexo: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'SODEXO_VOUCHER',
  paymentMethodBrand: 'SODEXO_VOUCHER',
  onlinePayment: false,
};

export const CHEQUE_GOURMET_VOUCHER_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  chequeGourmet: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'CHEQUE_GOURMET_VOUCHER',
  paymentMethodBrand: 'CHEQUE_GOURMET_VOUCHER',
  onlinePayment: false,
};

export const TICKET_RESTAURANT_VOUCHER_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  ticketRestaurant: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'TICKET_RESTAURANT_VOUCHER',
  paymentMethodBrand: 'TICKET_RESTAURANT_VOUCHER',
  onlinePayment: false,
};

export const SODEXO_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  sodexo: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'SODEXO',
  paymentMethodBrand: 'SODEXO',
  transient: true,
  onlinePayment: true,
};

export const CHEQUE_GOURMET_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  chequeGourmet: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'CHEQUE_GOURMET',
  paymentMethodBrand: 'CHEQUE_GOURMET',
  transient: true,
  onlinePayment: true,
};

export const TICKET_RESTAURANT_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  ticketRestaurant: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'TICKET_RESTAURANT_CARD',
  paymentMethodBrand: 'TICKET_RESTAURANT_CARD',
  transient: true,
  onlinePayment: true,
};

export const PAYMENT_ON_DELIVERY_CARD_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  fdAccountId: 'CASH',
  accountIdentifier: 'PAYMENT_ON_DELIVERY_CARD',
  paymentMethodBrand: 'PAYMENT_ON_DELIVERY_CARD',
  paymentOnDeliveryCard: true,
  onlinePayment: false,
};

export const BIZUM_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  bizum: true,
  fdAccountId: 'BIZUM',
  accountIdentifier: 'BIZUM',
  paymentMethodBrand: 'BIZUM',
  transient: true,
  onlinePayment: true,
};

export const WAYLET_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  waylet: true,
  fdAccountId: 'WAYLET',
  accountIdentifier: 'WAYLET',
  paymentMethodBrand: 'WAYLET',
  transient: true,
  onlinePayment: true,
};

export const APPLEPAYLINK_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  applePayLink: true,
  fdAccountId: 'APPLEPAYLINK',
  accountIdentifier: 'APPLEPAYLINK',
  paymentMethodBrand: 'APPLEPAYLINK',
  transient: true,
  onlinePayment: true,
};

export const MBWAY_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  fdAccountId: 'MBWAY',
  accountIdentifier: 'MBWAY',
  mbway: true,
  transient: true,
  onlinePayment: true,
};

export const MERCADOPAGO_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  mercadoPago: true,
  fdAccountId: 'MERCADOPAGO',
  accountIdentifier: 'MERCADOPAGO',
  paymentMethodBrand: 'MERCADOPAGO',
  transient: true,
  onlinePayment: true,
};
