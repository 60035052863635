import React, { SVGProps } from 'react';

export const CrownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="47"
    height="29"
    viewBox="0 0 47 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.4004 3.55485L38.8984 5.97533L35.3964 8.39581C34.8667 8.75631 34.2487 8.90345 33.6528 8.82988C33.0569 8.76367 32.483 8.4841 32.049 8.01324L28.9295 4.61427L25.8101 1.21529C25.1921 0.538439 24.3461 0.200012 23.5 0.200012C22.6539 0.200012 21.8079 0.538439 21.1899 1.21529L18.0705 4.61427L14.9511 8.01324C14.517 8.4841 13.9432 8.76367 13.3472 8.82988C12.7513 8.89609 12.1333 8.75631 11.6036 8.39581L8.10162 5.97533L4.59965 3.55485C3.70208 2.93685 2.64266 2.981 1.84074 3.45921C1.03881 3.93742 0.48703 4.83498 0.597386 5.92383L1.61266 16.003L2.62794 26.0823C2.69416 26.737 3.00315 27.3109 3.45929 27.7229C3.91543 28.1349 4.51872 28.385 5.18086 28.385H23.5H41.8192C42.474 28.385 43.0846 28.1349 43.5407 27.7229C43.9969 27.3109 44.3059 26.737 44.3721 26.0823L45.3874 16.003L46.4026 5.92383C46.513 4.83498 45.9612 3.93742 45.1593 3.45921C44.35 2.981 43.2979 2.93685 42.4004 3.55485Z"
      fill="#FFAA00"
    />
  </svg>
);
