import { createLazyRoutes } from './utils';

export const LazyRoutes = createLazyRoutes({
  AboutApp: () => import('pages/about-app'),
  AboutPage: () => import('pages/about'),
  Account: () => import('pages/account'),
  AccountDelete: () => import('pages/account/account-delete'),
  AccountInfo: () => import('pages/account/account-info'),
  AccountOrders: () => import('pages/account/account-orders'),
  AccountRequestInfo: () => import('pages/account/account-request-info'),
  AccountSettings: () => import('pages/account/account-settings'),
  AuthJwtChallengeScreen: () => import('pages/authentication/auth-email-jwt'),
  BkPay: () => import('pages/bkpay'),
  BlueLightCard: () => import('pages/account/blue-light-card'),
  Cart: () => import('pages/cart'),
  CateringBirthdaySection: () => import('pages/catering-birthday'),
  CommPreferences: () => import('pages/account/comm-preferences'),
  ConfirmJwt: () => import('pages/authentication/confirm-jwt'),
  Diagnostics: () => import('pages/diagnostics'),
  FavoriteEditor: () => import('pages/favorite-editor'),
  History: () => import('pages/account/history'),
  Home: () => import('pages/home'),
  InvitationCode: () => import('pages/account/invitation-code'),
  LoadingPage: () => import('pages/loading'),
  LoyaltyClaimPoints: () => import('pages/loyalty/loyalty-claim-points'),
  LoyaltyInRestaurantRedemption: () => import('pages/loyalty/loyalty-in-restaurant-redemption'),
  LoyaltyLinkCard: () => import('pages/loyalty/loyalty-link-card'),
  LoyaltyNotAvailableAtDeliveryMode: () =>
    import('pages/loyalty/loyalty-not-available-at-delivery-mode'),
  LoyaltyNotAvailableAtThisRestaurant: () =>
    import('pages/loyalty/loyalty-not-available-at-this-restaurant'),
  LoyaltyOffers: () => import('pages/loyalty/loyalty-offers'),
  LoyaltyRewardsMemo: () => import('pages/loyalty/loyalty-rewards'),
  LoyaltySurprise: () => import('pages/loyalty/loyalty-surprise'),
  MainMenu: () => import('pages/main-menu'),
  MembershipDetails: () => import('pages/account/membership-details'),
  Memberships: () => import('pages/account/memberships'),
  MenuContent: () => import('pages/menu-content'),
  NotFound: () => import('pages/not-found'),
  OffersPage: () => import('pages/offers'),
  OrderConfirmation: () => import('pages/order-confirmation/order-confirmation'),
  OrderProcessing: () => import('pages/order-processing/order-processing'),
  PaymentMethodPage: () => import('pages/cart/payment/payment-method'),
  PaymentMethods: () => import('pages/account/payment-methods'),
  PaymentPage: () => import('payments'),
  ReportOrderIssuePage: () => import('pages/report-order-issue'),
  ReportOrderItemsMissingPage: () => import('pages/report-order-items-missing'),
  ReportOrderNeverArrivedPage: () => import('pages/report-order-never-arrived'),
  SettingsLocation: () => import('pages/account/account-settings/settings-location'),
  SignOut: () => import('pages/authentication/sign-out'),
  SignupHome: () => import('pages/authentication/sign-up-home'),
  SignUpUnified: () => import('pages/authentication/sign-up-unified'),
  StaticPage: () => import('pages/static'),
  StoreLocator: () => import('pages/store-locator'),
  SupportPage: () => import('pages/support'),
  SupportV2CategoriesPage: () => import('pages/support-v2/support-v2-categories-page'),
  SupportV2CategoryPage: () => import('pages/support-v2/support-v2-category-page'),
  SupportV2FormPage: () => import('pages/support-v2/support-v2-form-page'),
  SupportV2OrdersListPage: () => import('pages/support-v2/support-v2-orders-list-page'),
  WaitingPaymentPage: () => import('pages/cart/payment/order-payment/waiting-payment'),
});
