import { flatMap, isEmpty, isUndefined } from 'lodash';

import { ICartEntry, IDiscount, IOfferDiscount, IPrices } from '@rbi-ctg/menu';
import { DiscountTypes, MenuObjectTypes, OfferDiscountTypes } from 'enums/menu';
import { getRewardPrice } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { IAppliedRewards } from 'state/loyalty/hooks/types';
import {
  ISanityRewardsMap,
  LoyaltyAppliedOffer,
  LoyaltyOffer,
  LoyaltyReward,
} from 'state/loyalty/types';
import { isDiscountOffer } from 'state/loyalty/utils';
import { dollarsToCents } from 'utils';
import { priceForCartEntry } from 'utils/menu/price';
import { PosVendors } from 'utils/vendor-config';

interface ICartEntryForPricing extends Pick<ICartEntry, 'price' | 'quantity' | 'cartId'> {}

interface IComputeSavings {
  cartEntries?: ICartEntryForPricing[];
  rewardDiscountAmount?: number;
  subTotalCents: number;
}

export const getIndividualItemPrice = ({
  cartEntry,
}: {
  cartEntry: ICartEntryForPricing;
}): number => (cartEntry.price || 0) / cartEntry.quantity;

export const getItemLoyaltyRewardDiscount = ({
  cartEntry,
  appliedLoyaltyRewards,
  sanityRewardsMap,
  isAmountOfferDiscountValueAsCents = false,
}: {
  cartEntry: ICartEntryForPricing;
  appliedLoyaltyRewards: IAppliedRewards;
  sanityRewardsMap?: ISanityRewardsMap;
  isAmountOfferDiscountValueAsCents?: boolean;
}) => {
  const appliedReward = appliedLoyaltyRewards[cartEntry.cartId];
  const individualItemPrice = getIndividualItemPrice({ cartEntry });
  const timesRewardApplied = appliedReward?.timesApplied ?? 0;

  const sanityReward = sanityRewardsMap?.[appliedReward?.sanityId!];
  const reward = sanityReward?.incentives?.[0] as IOfferDiscount;

  const rewardPrice = getRewardPrice(sanityReward as LoyaltyReward);

  // If reward is discount we need to calculate the price
  if (isOfferDiscount(reward)) {
    const discountAmount = computeTotalWithOfferDiscount(
      individualItemPrice,
      reward,
      isAmountOfferDiscountValueAsCents
    );
    return (individualItemPrice - discountAmount) * timesRewardApplied;
  }

  if (rewardPrice) {
    return (individualItemPrice - rewardPrice) * timesRewardApplied;
  }

  return individualItemPrice * timesRewardApplied;
};

export const getCartEntryBenefit = (
  cartEntries: ICartEntry[],
  getAvailableRewardFromCartEntry: (cartEntry: ICartEntry) => void,
  rewardRedemptionBenefitIdParam: string | null
) =>
  cartEntries?.find(entry => {
    const { _id } = entry ?? {};
    // in the case of pickers, the `_id` won't match `rewardRedemptionBenefitIdParam`
    // however we can derive the resolved item/combo using the `url` property on the cartEntry
    return _id === rewardRedemptionBenefitIdParam || getAvailableRewardFromCartEntry(entry);
  });

export const getLoyaltyRewardsDiscount = ({
  cartEntries,
  loyaltyEnabled,
  appliedLoyaltyRewards,
  sanityRewardsMap,
  isAmountOfferDiscountValueAsCents = false,
}: {
  cartEntries: ICartEntryForPricing[];
  loyaltyEnabled: boolean;
  appliedLoyaltyRewards?: IAppliedRewards;
  sanityRewardsMap?: ISanityRewardsMap;
  isAmountOfferDiscountValueAsCents?: boolean;
}) =>
  !loyaltyEnabled || isUndefined(appliedLoyaltyRewards) || isEmpty(appliedLoyaltyRewards)
    ? 0
    : cartEntries.reduce((totalRewardsDiscount: number, cartEntry: ICartEntry) => {
        const discount = getItemLoyaltyRewardDiscount({
          cartEntry,
          appliedLoyaltyRewards,
          sanityRewardsMap,
          isAmountOfferDiscountValueAsCents,
        });
        return totalRewardsDiscount + discount;
      }, 0);

export const getLoyaltyCartOfferDiscount = ({
  loyaltyEnabled,
  appliedLoyaltyOfferDiscount,
}: {
  loyaltyEnabled: boolean;
  appliedLoyaltyOfferDiscount?: IOfferDiscount;
}) => {
  return loyaltyEnabled &&
    isOfferDiscount(appliedLoyaltyOfferDiscount) &&
    !appliedLoyaltyOfferDiscount.discountProduct
    ? appliedLoyaltyOfferDiscount
    : undefined;
};

export const computeTotalWithOfferDiscount = (
  totalCents: number,
  offerDiscount?: IOfferDiscount | null,
  isAmountOfferDiscountValueAsCents = false
) => {
  const { discountValue, discountType } = offerDiscount ?? {};

  // We can't apply a discount if we don't have a discountValue
  if (!discountValue || discountValue === 0) {
    return totalCents;
  }

  if (discountType === OfferDiscountTypes.AMOUNT) {
    const discountValueInCents = Math.abs(
      isAmountOfferDiscountValueAsCents ? discountValue : dollarsToCents(discountValue)
    );

    // If discount amount is bigger than total, we return the total instead
    return discountValueInCents > totalCents ? 0 : totalCents - discountValueInCents;
  }

  if (discountType === OfferDiscountTypes.PERCENTAGE) {
    const discountPercentage = discountValue / 100;
    // If the discount percentage is over 100% the order is free...
    if (discountPercentage >= 1) {
      return 0;
    }
    const discountValueInCents = Math.round(totalCents * discountPercentage);
    return totalCents - discountValueInCents;
  }
  // there is a discountType we are not accounting for... Consider a log here to track this?
  return totalCents;
};

export const calculateDiscountValue = (
  totalCents: number,
  offerDiscount?: IOfferDiscount | null,
  isAmountOfferDiscountValueAsCents = false
) => {
  const discountPriceForItemOnly = computeTotalWithOfferDiscount(
    totalCents,
    offerDiscount,
    isAmountOfferDiscountValueAsCents
  );
  return totalCents - discountPriceForItemOnly;
};

export const computeCartTotal = (
  cartEntries: ICartEntry[],
  loyaltyInfo: {
    loyaltyEnabled: boolean;
    appliedLoyaltyRewards?: IAppliedRewards;
    appliedLoyaltyOfferDiscount?: IOfferDiscount;
    appliedOffers?: LoyaltyAppliedOffer[];
    loyaltyCmsOffers?: LoyaltyOffer[];
    sanityRewardsMap?: ISanityRewardsMap;
    prices?: IPrices;
    vendor?: PosVendors | null;
  },
  isAmountOfferDiscountValueAsCents = false
) => {
  const { appliedLoyaltyRewards, sanityRewardsMap } = loyaltyInfo;

  const loyaltyRewardsDiscount = getLoyaltyRewardsDiscount({
    cartEntries,
    ...loyaltyInfo,
    isAmountOfferDiscountValueAsCents,
  });

  const totalWithRewardsApplied =
    Math.max(
      cartEntries.reduce((totalPrice: number, entry: ICartEntry) => {
        const offerDetails = loyaltyInfo?.appliedOffers?.find(
          offer => offer.cartId === entry.cartId
        );
        const currentOffer = loyaltyInfo?.loyaltyCmsOffers?.find(
          offer => offer._id === offerDetails?.cmsId
        );
        const price = priceForCartEntry(entry);

        if (currentOffer) {
          const offerDiscount = currentOffer?.incentives?.[0] as IOfferDiscount;

          if (isDiscountOffer(currentOffer) && offerDiscount && price) {
            const discountedAmount = computeTotalWithOfferDiscount(
              price,
              offerDiscount,
              isAmountOfferDiscountValueAsCents
            );

            return discountedAmount + totalPrice;
          }
        }

        return price + totalPrice;
      }, 0),
      0
    ) - loyaltyRewardsDiscount;

  const rewardCartDiscount = appliedLoyaltyRewards?.['discount-offer'];
  const rewardCartDiscountValues = sanityRewardsMap?.[rewardCartDiscount?.sanityId ?? ''];
  // If the reward is a cart level discount we have to apply the discount to the entire cart price
  const totalWithRewardCartDiscountApplied = computeTotalWithOfferDiscount(
    totalWithRewardsApplied,
    rewardCartDiscountValues?.incentives?.[0] as IOfferDiscount,
    isAmountOfferDiscountValueAsCents
  );

  // When the offer has an offerDiscount calculate the discount off the entire order
  const offerDiscount = getLoyaltyCartOfferDiscount(loyaltyInfo);

  return Boolean(offerDiscount)
    ? computeTotalWithOfferDiscount(
        totalWithRewardCartDiscountApplied,
        offerDiscount,
        isAmountOfferDiscountValueAsCents
      )
    : totalWithRewardCartDiscountApplied;
};

export const computeOtherDiscountAmount = (allDiscounts?: IDiscount[]) => {
  if (!allDiscounts) {
    return 0;
  }
  return allDiscounts
    .filter(discount => discount.name !== DiscountTypes.REWARDS_DISCOUNTS)
    .reduce((acc, curr) => curr.value + acc, 0);
};

export const computeSavings = (orderSummary: IComputeSavings): number => {
  if (!orderSummary.cartEntries || orderSummary.subTotalCents === 0) {
    return 0;
  }
  const orderTotal =
    orderSummary.cartEntries.reduce(
      (totalPrice, cartEntry) => (cartEntry.price ?? 0) + totalPrice,
      0
    ) ?? 0;
  return Math.max(
    orderTotal - (orderSummary.rewardDiscountAmount ?? 0) - orderSummary.subTotalCents,
    0
  );
};

export const computeDeliveryFee = ({
  feeCents,
  feeDiscountCents,
  serviceFeeCents,
  smallCartFeeCents,
  geographicalFeeCents,
}: {
  feeCents: number | null;
  feeDiscountCents: number | null;
  serviceFeeCents: number | null;
  smallCartFeeCents: number | null;
  geographicalFeeCents: number | null;
}) => {
  // The value "feeCents" includes the additional fees "serviceFeeCents" and "smallCartFeeCents"
  return Math.max(
    (feeCents || 0) -
      (feeDiscountCents || 0) -
      (serviceFeeCents || 0) -
      (smallCartFeeCents || 0) -
      (geographicalFeeCents || 0),
    0
  );
};

export const isOfferDiscount = (option: any): option is IOfferDiscount =>
  option?._type === MenuObjectTypes.OFFER_DISCOUNT;

/**
 * Find first OfferDiscount benefit inside an Offers list, if any
 *
 * @param offers A LoyaltyOffer array
 */
export const getAppliedLoyaltyOfferDiscount = (
  offers?: LoyaltyOffer[]
): IOfferDiscount | undefined => {
  const benefits = flatMap(offers, offer => offer.incentives);
  const [firstOfferDiscount] = benefits.filter(isOfferDiscount) as IOfferDiscount[];
  return firstOfferDiscount;
};

/**
 * Computes cart total without including discount offers
 *
 * @param cartEntries
 * @param loyaltyInfo loyalty required info including applied rewards to calculate total
 */
export const computeTotalWithoutOffers = (
  cartEntries: ICartEntry[],
  loyaltyInfo: {
    loyaltyEnabled: boolean;
    appliedLoyaltyRewards?: IAppliedRewards;
    sanityRewardsMap?: ISanityRewardsMap;
    prices?: IPrices;
    vendor?: PosVendors | null;
  },
  isAmountOfferDiscountValueAsCents = false
) => {
  return computeCartTotal(cartEntries, loyaltyInfo, isAmountOfferDiscountValueAsCents);
};

/**
 * Calculate the value of fee with discount
 *
 * @param value
 * @param discount
 */
export const calculateFeeValue = (value: number, discount: number | null) =>
  value - (discount || 0);
