import styled from 'styled-components';

import { View } from 'components/view';

export const ContentLoadingContainer = styled(View)`
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
