import { createGlobalStyle } from 'styled-components';

import { IFontConfig } from '../constants/fonts';

export const WebGlobalStyle = createGlobalStyle<{ $fontConfig: IFontConfig }>`
/* These styles make the body full-height */
html, body { height: 100%; }
/* These styles disable body scrolling if you are using <ScrollView> */
body { overflow: hidden !important; }

html {
  font-size: ${p => p.$fontConfig.baseFontSize}px;
}

[data-mediaquery="mobile"],[data-mediaquery="headerMobile"], [data-mediaquery="desktop"], [data-mediaquery="desktopLarge"]  {
  display: none;
}

${Styles.mobile} {
  [data-mediaquery="mobile"] {
    display: initial;
  }
}

${Styles.headerMobile} {
  [data-mediaquery="headerMobile"] {
    display: initial;
  }
}

${Styles.desktop} {
  [data-mediaquery="desktop"] {
    display: initial;
  }
}


${Styles.desktopLarge} {
  [data-mediaquery="desktopLarge"] {
    display: initial;
  }
}

`;

export const SkeletonStyles = createGlobalStyle`
 @keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
}

.react-loading-skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}
`;
