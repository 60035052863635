import React, { SVGProps } from 'react';

export const RewardsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="13"
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18.7474 1.46393L17.2193 2.52014L15.6912 3.57635C15.46 3.73366 15.1903 3.79786 14.9303 3.76576C14.6703 3.73687 14.4199 3.61487 14.2304 3.40941L12.8693 1.92622L11.5081 0.443031C11.2384 0.147677 10.8692 0 10.5 0C10.1308 0 9.76161 0.147677 9.49194 0.443031L8.13075 1.92622L6.76955 3.40941C6.58014 3.61487 6.32973 3.73687 6.06969 3.76576C5.80965 3.79465 5.53998 3.73366 5.30884 3.57635L3.7807 2.52014L2.25257 1.46393C1.8609 1.19426 1.39861 1.21352 1.04868 1.42219C0.698751 1.63087 0.457972 2.02253 0.506128 2.49767L0.949157 6.89587L1.39219 11.2941C1.42108 11.5798 1.55592 11.8302 1.75496 12.01C1.954 12.1898 2.21725 12.2989 2.50619 12.2989H10.5H18.4938C18.7795 12.2989 19.046 12.1898 19.245 12.01C19.4441 11.8302 19.5789 11.5798 19.6078 11.2941L20.0508 6.89587L20.4939 2.49767C20.542 2.02253 20.3013 1.63087 19.9513 1.42219C19.5982 1.21352 19.1391 1.19426 18.7474 1.46393Z" />
  </svg>
);
