import styled from 'styled-components';

import { primitive } from 'styles/constants/primitives';

import { NavLink } from './helpers';

export const IconWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;

  & > svg {
    position: relative;
    height: 100%;
    fill: ${primitive.bk.$bbqBrown};
  }
`;

export const NavLinkWithIcon = styled(NavLink)`
  align-items: center;
  justify-content: flex-start;
  letter-spacing: unset;
  line-height: unset;
  gap: 0.25rem;
  min-height: 3.125rem;
  height: 100%;

  &.active {
    ${IconWrap} > svg {
      fill: ${Styles.color.primary};
    }
  }
`;

export const NavLinkText = styled.div`
  font-size: 0.75rem;
  font-family: ${Styles.fontFamily.body};

  word-break: break-word;
  overflow-wrap: break-word;
`;
