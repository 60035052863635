import React, { FC, ReactNode, useContext } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ActionLink } from 'components/action-button';
import { AuthContext } from 'state/auth';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

import { SupportLink } from './support-link';
import { theme } from './theme';

interface IOrderId {
  orderId: string;
}

export const verticalCenteringStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  /* 57px: height of fixed header, 3rem: vertical container padding */
  min-height: calc(100vh - 57px - 3rem);

  ${Styles.headerMobile} {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

export const MapContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${verticalCenteringStyles};
`;

export const OrderCardScroller = styled.div`
  position: absolute;
  width: 100%;
  overflow-x: auto;
  inset-block-end: 0;
  inset-inline-start: 0;

  ${Styles.desktopLarge} {
    inset-block-end: unset;
    inset-inline-start: 0;
    inset-block-start: 8rem;
    width: 100%;
  }
`;

const OrderIssuesContainer = styled.div`
  color: ${Styles.color.black};
  font-size: 0.875rem;
  text-align: center;
  padding: 1rem;
  max-width: 311px;
  border-block-start: solid 1px ${Styles.color.grey.four};
  margin-block-start: 1.5rem;
  margin-block-end: 0;
  margin-inline: auto;
`;

const OrderIssuesHeading = styled.h3`
  font-size: 1rem;
  margin-block-start: 0;
  margin-block-end: 0.625rem;
  margin-inline: 0;
  font-family: ${Styles.fontFamily.base};
  font-weight: 600;
`;

const OrderIssuesText = styled.p`
  margin: 0;
  font-size: 0.75rem;
`;

const OrderIdContainer = styled.div`
  color: #71717a;
  font-size: 0.875rem;
  text-align: center;
  padding: 1.375rem;
  margin-block-start: auto;
  margin-block-end: 3rem;
  margin-inline: auto;
  width: 90%;
  display: flex;
  justify-content: center;

  ${Styles.desktop} {
    max-width: 400px;
  }
`;

export const OrderIssues: FC<{ children?: ReactNode }> = ({ children }) => {
  const { formatMessage } = useIntl();

  return (
    <OrderIssuesContainer>
      <OrderIssuesHeading>{formatMessage({ id: 'needHelp' })}</OrderIssuesHeading>
      <OrderIssuesText>
        {formatMessage({ id: 'issuesDuringExperience' })}&nbsp;
        <SupportLink />
      </OrderIssuesText>
      {children}
    </OrderIssuesContainer>
  );
};

export const OrderId: React.FC<IOrderId> = ({ orderId }) => {
  const { formatMessage } = useIntl();
  return (
    <OrderIdContainer data-testid="order-id">
      {formatMessage({ id: 'orderId' }, { orderId })}
    </OrderIdContainer>
  );
};

export const CTA: React.FC = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { formatMessage } = useIntl();

  return isAuthenticated() ? (
    <Box margin="0 auto">
      <ActionLink to={routes.base}>{formatMessage({ id: 'home' })}</ActionLink>
    </Box>
  ) : (
    <Box margin="0 auto">
      <ActionLink to={routes.account}>{formatMessage({ id: 'signUp' })}</ActionLink>
    </Box>
  );
};

export const OrderCardScrollContainer = styled.div`
  padding: 1.25rem;
  display: inline-flex;
  overflow-x: visible;

  ${Styles.desktopLarge} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const OrderCard = styled.div`
  background: ${Styles.color.white};
  border-radius: ${Styles.borderRadius};
  box-shadow: ${Styles.boxShadow};
  padding-block-start: 2.25rem;
  padding-block-end: 1.438rem;
  padding-inline: 2.25rem;
  width: calc(100vw - 3rem);
  margin-inline-end: 1rem;

  ${Styles.desktopLarge} {
    width: 80%;
    max-width: 500px;
  }
`;

export const SignupCard = styled(OrderCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin-inline-end: 4rem;

  ${Styles.desktopLarge} {
    display: none;
  }
`;

export const HeroHeading = styled.h2`
  color: ${Styles.color.black};
  font-family: ${Styles.fontFamily.base};
  line-height: 1;
  margin-block-start: 3.125rem;
  margin-block-end: 2.375rem;
  margin-inline: auto;
  text-align: center;
  text-transform: ${theme.heroHeadingTextTransform};
  width: 310px;

  > small {
    text-transform: lowercase;
    vertical-align: top;
  }
`;

export const LogoContainer = styled.div`
  margin: auto;
  text-align: center;
  width: 8rem;

  svg {
    fill: ${Styles.color.primary};
    width: 4.5rem;
  }

  ${Styles.desktopLarge} {
    svg {
      width: 8rem;
    }
  }
  ${Styles.mobileTiny} {
    margin-block-start: 7.5rem;
  }
`;

export const NarrowSection = styled.div`
  max-width: ${Styles.layout.smallerSectionMaxWidth};
  margin: auto;
  padding: 0 1.5rem;
  ${Styles.desktopLarge} {
    padding: 0 2.75rem;
  }
`;

export const QRContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-start: 2em;
  margin-block-end: 1em;
`;

export const QRWrapper = styled.div`
  padding: 0.75em;
  background-color: ${primitive.$white};
  & > canvas {
    display: block;
  }
`;

export const PickupPinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
  padding: 1rem;
  margin: 2rem auto;
  border-radius: 0.5rem;
  background-color: ${primitive.$white};
`;

export const PickupPinCode = styled.span`
  font-size: 2.25rem;
  line-height: 1;
  font-family: ${Styles.fontFamily.base};
`;

export const PickupPinMessage = styled.span`
  font-size: 1rem;
  line-height: 1;
`;
