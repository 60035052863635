import * as React from 'react';

import { useNavigate } from 'react-router';

import { routes } from 'utils/routing';

import {
  ActionContainer,
  Container,
  Details,
  DetailsContainer,
  Heading,
  IconContainer,
  NotificationWrapper,
} from './top-service-mode-notification.styled';
import { ITopServiceModeNotificationViewProps } from './types';

export const TopServiceModeNotificationView = ({
  heading,
  icon,
  orderId,
  details,
  cta,
  className = '',
}: ITopServiceModeNotificationViewProps) => {
  const navigate = useNavigate();

  const navigateToOrder = () => {
    if (orderId) {
      navigate(`${routes.orderConfirmation}/${orderId}`);
    }
  };

  return (
    <Container className={className}>
      <NotificationWrapper>
        <IconContainer data-testid="service-notification-icon">{icon}</IconContainer>
        <DetailsContainer onClick={() => navigateToOrder()}>
          {heading && <Heading data-testid="service-notification-heading">{heading}</Heading>}
          {details && <Details data-testid="service-notification-details">{details}</Details>}
        </DetailsContainer>
        <ActionContainer>{cta}</ActionContainer>
      </NotificationWrapper>
    </Container>
  );
};
