/* globals google */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { debounce, noop } from 'lodash';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { ILocation } from '@rbi-ctg/frontend';
import { useGoogleGeolocationLibrary } from 'hooks/geolocation/use-google-geolocation-library';
import { useMediaQuery } from 'hooks/use-media-query';
import { useWindowDimensions } from 'hooks/use-window-dimensions';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { getRandomId } from 'utils';
import { brand } from 'utils/environment';
import { isValidPosition } from 'utils/geolocation';
import { cornersForPoint, hypotenuse } from 'utils/geometry';
import { getAppCountry } from 'utils/i18n';
import * as location from 'utils/location';
import { defaultMapZoom } from 'utils/restaurant/default-map-zoom';

import { mapDefaults } from './defaults';
import {
  addListener,
  clearListenerCache,
  createDestinationMarker,
  createDriverLocationMarker,
  createStoreLocationMarker,
  createUserLocationMarker,
  destinationIconUrl,
  driverIconUrl,
  getBrandStoreIcon,
  storeIconUrl,
} from './map-api-utils';
import { styleMapDefault } from './style-map/style-map-default';
import { styleMapGrey } from './style-map/style-map-grey';

const MapContainer = styled.div`
  background: ${Styles.color.white};
  flex: 1 1 auto;
  height: 100%;
  position: relative;
  width: 100%;
`;

const MapMarkerStyle = createGlobalStyle<{ $background: string }>`
  #googleMapMarkers img {
    text-indent: -10000px;

  }
`;

export enum MarkerTypes {
  User = 'user',
  Store = 'store',
  Destination = 'destination',
  Driver = 'driver',
}

interface IDeriveMarker {
  type: MarkerTypes;
  location: ILocation;
  onClick(): void;
  iconOverrideUrl?: string;
  source?: string;
}

type Marker = {
  marker: google.maps.Marker;
  inactive(): void;
  active(): void;
};

type TMarkersMap = Record<string, Marker>;

const computeHeight = (innerHeight: number, isMobile: boolean) =>
  isMobile ? innerHeight - 56 - 50 : innerHeight - 78;

type MapEventListener = (...args: any[]) => void;
export interface IUseMap {
  disableControls?: boolean;
  eventListeners?: {
    [key: string]: MapEventListener | Array<MapEventListener>;
  };
  position?: ILocation;
  showMarkerClick?: boolean;
  onMarkerAdded?: (location: ILocation) => void;
}

export const useMap = ({
  eventListeners = {},
  position = {} as ILocation,
  showMarkerClick,
  onMarkerAdded,
  ...props
}: IUseMap = {}) => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const appCountry = getAppCountry() as keyof typeof mapDefaults;
  const staggerIndex = useRef(0);
  const defaultPosition = mapDefaults[appCountry] || mapDefaults.default;
  const maxZoom = defaultPosition.maxZoom;
  const markerIcon = `${brand()}/delivery-address-pin.svg`;
  const markerIconPath = `${location.get('origin')}/assets/${markerIcon}`;
  // track when the map container has been loaded into the dom
  const [mapContainerLoadedInDom, setMapContainerLoadedInDom] = useState(false);
  const { libraryLoaded } = useGoogleGeolocationLibrary();
  const startPosition = position.lat
    ? {
        lat: position.lat,
        lng: position.lng,
      }
    : defaultPosition;
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const markers = useRef<TMarkersMap>({});
  const [zoom, setZoom] = useState(
    startPosition !== defaultPosition ? defaultPosition.zoom : defaultMapZoom
  );
  const [marketIconUrl, setMarketIconUrl] = useState('');
  const { width: innerWidth, height: innerHeight } = useWindowDimensions();
  const isMobile = useMediaQuery('mobile');
  /**
   * these are only computed once to ensure any
   * groq queries sent out have reasonable defaults
   * since map may not be drawn by the time first query fires
   */
  /* eslint-disable react-hooks/exhaustive-deps */
  const height = useMemo(() => computeHeight(innerHeight, isMobile), []);
  const diagonal = useMemo(() => hypotenuse(innerWidth, height), []);
  const { ne, sw } = useMemo(
    () => cornersForPoint(startPosition.lat, startPosition.lng, zoom, diagonal),
    []
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const [neLat, setNeLat] = useState(ne.lat);
  const [neLng, setNeLng] = useState(ne.lng);
  const [swLat, setSwLat] = useState(sw.lat);
  const [swLng, setSwLng] = useState(sw.lng);
  const [center, setCenter] = useState(startPosition);

  const panTo = useCallback(
    (newPosition: ILocation) => {
      if (!map) {
        return;
      }

      if (isValidPosition(newPosition)) {
        map.panTo(newPosition);
      }

      const zoomLevel = map?.getZoom();
      if (zoomLevel && zoomLevel < defaultMapZoom && newPosition !== defaultPosition) {
        map.setZoom(defaultMapZoom);
      }
    },
    [defaultPosition, map]
  );

  /*
   * SETUP MAP
   *
   * This loads the map api internally, sets up some options
   * and begins the whole process.
   *
   * NOTES:
   * - This needs to be an effect
   * - It has required listener clean ups
   * - Can not re-render map created from this hook, will error.
   *
   */

  const enableCustomStyleMap = useFlag(LaunchDarklyFlag.ENABLE_CUSTOM_STYLE_MAP);
  const enableMapFallBack = useFlag(LaunchDarklyFlag.ENABLE_MAP_FALLBACK);

  const prepareMap = useCallback(
    (mapId: string) => {
      const options = {
        center: startPosition,
        styles: enableCustomStyleMap ? styleMapGrey : styleMapDefault,
        disableDefaultUI: true,
        zoom,
        gestureHandling: props.disableControls ? 'none' : 'greedy',
      } as google.maps.MapOptions;

      // @ts-ignore
      const mapInstance = new window.google.maps.Map(domRef.current, options);

      const setClampedMapBounds = () => {
        const mapBounds = mapInstance.getBounds() as google.maps.LatLngBounds;
        const mapCenter = mapInstance.getCenter() as google.maps.LatLng;

        const northEast = mapBounds.getNorthEast();
        const southWest = mapBounds.getSouthWest();

        ReactDOM.unstable_batchedUpdates(() => {
          setNeLat(northEast.lat());
          setNeLng(northEast.lng());
          setSwLat(southWest.lat());
          setSwLng(southWest.lng());
          setCenter({
            lat: mapCenter.lat(),
            lng: mapCenter.lng(),
          });
        });
      };

      window.google.maps.event.addListenerOnce(mapInstance, 'idle', () => {
        const mapZoom = mapInstance.getZoom();
        if (mapZoom) {
          setZoom(mapZoom);
        }
        setClampedMapBounds();
      });

      /**
       *  Called by dragstart and zoom_changed listeners to prevent keyboard remaining open on android devices
       *  when dragging/zooming map while search field has focus
       */
      const blurActiveElement = () => {
        const activeElement = document.activeElement as HTMLElement | null;
        activeElement?.blur?.();
      };

      let marker: any = null;

      if (showMarkerClick && enableMapFallBack) {
        addListener(
          'click',
          mapInstance,
          (mouseEvent: any) => {
            if (marker) {
              marker.setMap(null);
            }

            if (typeof onMarkerAdded === 'function') {
              onMarkerAdded(mouseEvent.latLng.toJSON());
            }

            marker = new window.google.maps.Marker({
              position: mouseEvent.latLng,
              map: mapInstance,
              icon: markerIconPath,
            });
            panTo(mouseEvent.latLng);
          },
          mapId
        );
      }

      addListener('dragstart', mapInstance, blurActiveElement, mapId);

      addListener('bounds_changed', mapInstance, debounce(setClampedMapBounds, 500), mapId);

      addListener(
        'zoom_changed',
        mapInstance,
        debounce(() => {
          blurActiveElement();
          const mapZoom = mapInstance.getZoom();
          if (mapZoom) {
            setZoom(mapZoom);
          }
        }, 500),
        mapId
      );

      Object.keys(eventListeners).forEach(eventName => {
        const handlers = eventListeners[eventName];
        if (typeof handlers === 'function') {
          addListener(eventName, mapInstance, handlers, mapId);
        }
        if (Array.isArray(handlers)) {
          handlers.forEach(handler => {
            addListener(eventName, mapInstance, handler, mapId);
          });
        }
      });
      // Set a minZoom option to prevent the user to zoom out too much
      mapInstance.setOptions({ minZoom: 5, maxZoom });
      setMap(mapInstance);
    },
    [
      enableCustomStyleMap,
      enableMapFallBack,
      eventListeners,
      markerIconPath,
      onMarkerAdded,
      panTo,
      props.disableControls,
      showMarkerClick,
      startPosition,
      zoom,
    ]
  );

  useEffect(() => {
    const shouldLoad = !!domRef.current;

    if (!shouldLoad) {
      return;
    }

    const mapId = getRandomId();

    prepareMap(mapId);

    return () => clearListenerCache(mapId);
    /*
     * NOTE:
     * - Only run when we know the map container has been loaded in the dom ~ mapContainerLoadedInDom
     * - mapContainerLoadedInDom starts as false and is only set to true when we have a dom ref
     * - And the domRef.current will be set
     */
  }, [mapContainerLoadedInDom]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLoadedIntoDom = useCallback((ref: HTMLDivElement | null) => {
    if (!domRef.current && ref) {
      domRef.current = ref;
      setMapContainerLoadedInDom(true);
    }
  }, []);

  useEffect(() => {
    if (map && isValidPosition(position)) {
      panTo(position);
    }
    // We recreate a new object above each render, which makes a simple object
    // compare fail. We only want to run this effect when the actual values change
  }, [map, position.lat, position.lng]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
   * Returns marker of proper type.
   */
  const deriveMarker = useCallback(
    ({ type, location, onClick, iconOverrideUrl, source }: IDeriveMarker): Marker | void => {
      if (isValidPosition(location)) {
        switch (type) {
          case MarkerTypes.User:
            return createUserLocationMarker(location, map);
          case MarkerTypes.Store:
            if (source === 'orderConfirmation') {
              setMarketIconUrl(getBrandStoreIcon());
            } else {
              setMarketIconUrl(storeIconUrl);
            }
            return createStoreLocationMarker(location, map, onClick, source);
          case MarkerTypes.Driver:
            setMarketIconUrl(iconOverrideUrl || driverIconUrl);
            return createDriverLocationMarker(location, map, iconOverrideUrl);
          case MarkerTypes.Destination:
            setMarketIconUrl(destinationIconUrl);
            return createDestinationMarker(location, map);

          default:
            throw new Error(`Unsupported type: ${type}`);
        }
      }
    },
    [map]
  );

  interface MarkerOptions {
    type: MarkerTypes;
    location?: ILocation;
    onClick?: VoidFunction;
    introAnim?: boolean;
    iconOverrideUrl?: string;
    source?: string;
  }

  /*
   * Creates a marker for a given type and location. If marker
   * already placed on map, updates accordingly.
   */
  const createMarker = useCallback(
    ({
      type,
      location,
      onClick = noop,
      introAnim = false,
      iconOverrideUrl,
      source,
    }: MarkerOptions) => {
      const isSingleUseMarker = [MarkerTypes.User, MarkerTypes.Driver].includes(type);

      if (!libraryLoaded || !map) {
        return null;
      }

      if (!location || !isValidPosition(location)) {
        return null; // bail if location is not known
      }

      const markerOverlay = new google.maps.OverlayView();
      markerOverlay.draw = function () {
        //this assigns an id to the markerlayer Pane, so it can be referenced by CSS
        const panes = this.getPanes();
        if (panes) {
          panes.markerLayer.id = 'googleMapMarkers';
        }
      };
      markerOverlay.setMap(map);

      // This updates the marker if it has already been added to map.
      const key = isSingleUseMarker ? type : `${location.lat}${location.lng}`;

      if (markers.current[key]) {
        markers.current[key].marker.setMap(map);
        window.google.maps.event.clearInstanceListeners(markers.current[key].marker);
        window.google.maps.event.addListener(markers.current[key].marker, 'click', onClick);

        if (isSingleUseMarker && location.lat && location.lng) {
          markers.current[key].marker.setPosition(location);
        }

        return markers.current[key];
      }

      const marker = deriveMarker({ type, location, onClick, iconOverrideUrl, source }) as Marker;
      markers.current[key] = marker;

      // This animates the markers in if introAnim set to true
      if (type === MarkerTypes.Store && introAnim) {
        marker.marker.setOpacity(0);
        staggerIndex.current += 1;
        setTimeout(() => {
          marker.marker.setAnimation(window.google.maps.Animation.DROP);
          marker.marker.setOpacity(1);
          staggerIndex.current = Math.max(staggerIndex.current - 1, 0);
        }, 60 * staggerIndex.current);
      }

      return marker;
    },
    [deriveMarker, libraryLoaded, map]
  );

  const fitAndCenterFromCoords = useCallback(
    debounce((coordsArray: ILocation[]) => {
      if (!libraryLoaded || !map) {
        return;
      }

      if (coordsArray.length >= 2) {
        const markerBounds = coordsArray.reduce((bounds, coord) => {
          bounds.extend(coord);
          return bounds;
        }, new window.google.maps.LatLngBounds());

        const marker1 = new window.google.maps.LatLng({
          lat: markerBounds.getSouthWest().lat(),
          lng: markerBounds.getSouthWest().lng(),
        });
        const marker2 = new window.google.maps.LatLng({
          lat: markerBounds.getNorthEast().lat(),
          lng: markerBounds.getNorthEast().lng(),
        });

        if (!marker1.equals(marker2)) {
          // Adding a 100px padding around the bounds to cover for
          // icons at the edge
          map.fitBounds(markerBounds, 100);
        }
      }
    }, 500),
    [map, libraryLoaded]
  );

  return {
    createMarker,
    zoom,
    neLat,
    neLng,
    swLat,
    swLng,
    center,
    markers,
    domRef,
    map: (
      <>
        {marketIconUrl && <MapMarkerStyle $background={marketIconUrl} />}
        <MapContainer ref={onLoadedIntoDom}></MapContainer>
      </>
    ),
    mapInstance: map,
    MarkerTypes,
    panTo,
    fitAndCenterFromCoords,
  };
};
