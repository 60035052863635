import React, { useEffect } from 'react';

import { defaultTo } from 'lodash';
import { useIntl } from 'react-intl';
import { styled } from 'styled-components';

import { useDialogModal } from 'hooks/use-dialog-modal';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import { getAddressMessage, getOrderingDisabledStoreSelectionMessage } from 'utils/service-mode';

import { ChangeServiceModeLink } from '../change-service-mode-link';
import { IServiceModeAddressProps } from '../types';

const ServiceModeAddressWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ServiceModeAddress: React.FC<IServiceModeAddressProps> = ({
  serviceMode,
  userAddress,
}) => {
  const { formatMessage } = useIntl();
  const { isStoreOpenAndAvailable, isStoreOpenAndUnavailable, noStoreSelected, store } =
    useStoreContext();
  const enableOrdering = defaultTo(useFlag(LaunchDarklyFlag.ENABLE_ORDERING), true);
  const { showStaticMenu } = useMenuContext();

  const addressMessage = getAddressMessage({
    store,
    noStoreSelected,
    isStoreOpenAndUnavailable,
    serviceMode,
    userAddress,
    formatMessage,
  });

  const orderingDisabledStoreSelectionMessage = getOrderingDisabledStoreSelectionMessage({
    store,
    formatMessage,
    isStoreOpenAndAvailable,
  });

  let primaryText = orderingDisabledStoreSelectionMessage;

  if (enableOrdering) {
    primaryText = showStaticMenu ? formatMessage({ id: 'forItemAvailability' }) : addressMessage;
  }

  /**
   * NMS-424: Temporary mechanism to alert PLK ES users of failing credit card payments
   * @todo DELETE
   */

  const iberiaWarningModalRestaurantNumbers: string[] =
    useFlag(LaunchDarklyFlag.IBERIA_WARNING_MODAL_NO_CREDIT_CARD_RESTAURANTS) || [];

  const showModalIberia = iberiaWarningModalRestaurantNumbers.indexOf(store.number || '') > -1;
  const [AlertDialog, openAlert, alertMessage] = useDialogModal({
    onConfirm: () => {
      return;
    },
    modalAppearanceEventMessage: 'Alert: Payment Failed',
  });

  useEffect(() => {
    if (showModalIberia) {
      openAlert({
        body: formatMessage({ id: 'noCreditCardAccept' }),
        buttonLabel: formatMessage({ id: 'accept' }),
        heading: formatMessage({ id: 'headsUpModalTitle' }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalIberia]);

  return (
    <ServiceModeAddressWrapper data-testid="service-mode-address">
      <AlertDialog {...(alertMessage || {})} />
      <h3>{primaryText}</h3>
      <ChangeServiceModeLink
        storeSelected={isStoreOpenAndAvailable}
        orderingEnabled={enableOrdering}
      />
    </ServiceModeAddressWrapper>
  );
};
