import { IsoCountryCode2, IsoCountryCode3, Language, Locale } from '@rbilabs/intl';

export * from './constants.base';

export const PHONE_NUMBER_DUMMY = '00000000';
export const PHONE_NUMBER_DUMMY2 = '4258675309';
export const FALLBACK_ZIPCODE_REGEX = /^([a-zA-Z0-9\\\\s_-]){4,10}$/;
export const FALLBACK_LOCALES = [Locale.en];
export const FALLBACK_LANGUAGE = Language.en;
export const FALLBACK_ISO2 = IsoCountryCode2.AQ;
export const FALLBACK_ISO3 = IsoCountryCode3.ATA;
export const FALLBACK_CURRENCY_SYMBOL = '$';
export const FALLBACK_AVAILABLE_HOURS = [{ startTime: '00:00', endTime: '23:59' }];
//This is temp solution for recover ios user loyalty points(BK ES)
export const LOYALTY_POINT_MISSING_ISSUE_ID = '107';
