import React from 'react';

import { PrimaryButton, SecondaryButton } from '@rbilabs/components-library/build/components';
import { Flex } from '@rbilabs/components-library/build/components/flex';
import { Container } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useDistanceWarning } from 'hooks/use-distance-warning';
import { useMediaQuery } from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { StoreCardButtonOptions } from 'state/launchdarkly/variations';
import { convertCountryStringToIsoCountryCode2 } from 'utils/i18n';

import { IActionOption } from '../../store-actions/types';
import {
  Body2,
  LoyaltyTag,
  LoyaltyTagsContainer,
  StyledLoyaltyIcon,
  Subtitle2,
  ViewDetailsLink,
} from '../store-card.styled';

interface StoreCardDetailsProps {
  body?: string;
  caption: string | JSX.Element | null;
  errorMessage?: JSX.Element | null;
  title?: string | null;
  inStoreLoyalty?: boolean | null;
  mobileLoyalty?: boolean | null;
}

interface ImprovedStoreCardDetailsProps extends StoreCardDetailsProps {
  storeActionOptions: IActionOption[];
  restaurant: IRestaurantNode;
}

export const StoreCardDetails: React.FC<StoreCardDetailsProps> = ({
  body,
  caption,
  errorMessage,
  title,
  inStoreLoyalty,
  mobileLoyalty,
}) => {
  const { formatMessage } = useIntl();
  const enableLoyaltyTags = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_TAGS_STORE_LOCATOR);

  // If the mobile tag string is not populated, don't display flag
  const showMobileLoyaltyTag = formatMessage({ id: 'loyaltyStoreLocatorMobile' }).trim();

  return (
    <Container overflow="hidden">
      {title && <Subtitle2>{title.toLowerCase()}</Subtitle2>}

      <Body2>{caption}</Body2>
      {body && <Body2>{body}</Body2>}

      {enableLoyaltyTags && (
        <LoyaltyTagsContainer>
          {inStoreLoyalty && (
            <LoyaltyTag mobile={false}>
              <StyledLoyaltyIcon />
              {formatMessage({ id: 'loyaltyStoreLocatorInStore' })}
            </LoyaltyTag>
          )}

          {mobileLoyalty && showMobileLoyaltyTag ? (
            <LoyaltyTag mobile={true}>
              <StyledLoyaltyIcon />
              {formatMessage({ id: 'loyaltyStoreLocatorMobile' })}
            </LoyaltyTag>
          ) : null}
        </LoyaltyTagsContainer>
      )}

      {errorMessage}
    </Container>
  );
};

export const ImprovedStoreCardDetails: React.FC<ImprovedStoreCardDetailsProps> = ({
  body,
  caption,
  errorMessage,
  inStoreLoyalty,
  mobileLoyalty,
  storeActionOptions,
  restaurant,
}) => {
  const isMobile = useMediaQuery('mobile');
  const { formatMessage } = useIntl();
  const enableLoyaltyTags = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_TAGS_STORE_LOCATOR);

  const {
    isLocationConfirmationNeeded,
    ConfirmLocationModal,
    dismissDialog,
    openConfirmationModal,
    isLoading: isLoadingLocationConfirmation,
    isGeolocationEnabled,
  } = useDistanceWarning({ isMobileOrdering: true });

  const infoButton = storeActionOptions.find(
    storeAction => storeAction.id === StoreCardButtonOptions.INFO
  );
  const offersButton = storeActionOptions.find(
    storeAction => storeAction.id === StoreCardButtonOptions.OFFERS
  );
  const orderButton = storeActionOptions.find(
    storeAction => storeAction.id === StoreCardButtonOptions.ORDER
  );

  const getOrderOption = async () => {
    const hasCoordinates =
      typeof restaurant?.latitude === 'number' && typeof restaurant?.longitude === 'number';

    if (!hasCoordinates) {
      return orderButton?.onClick();
    }

    const restaurantCoordinates = {
      lat: restaurant.latitude,
      lng: restaurant.longitude,
    };

    const isFarAway = await isLocationConfirmationNeeded(restaurantCoordinates);

    return isFarAway ? openConfirmationModal() : orderButton?.onClick();
  };

  const onConfirmDistanceWarning = () => {
    orderButton?.onClick();
    dismissDialog();
  };

  const isOrderActionDisabled =
    orderButton?.loading || isLoadingLocationConfirmation || orderButton?.disabled;
  const isOrderActionLoading = orderButton?.loading || isLoadingLocationConfirmation;

  return (
    <>
      <Flex justifyContent="space-between">
        <Container overflow="hidden">
          <Body2>{caption}</Body2>

          {body && <Body2>{body}</Body2>}

          {infoButton && (
            <ViewDetailsLink onClick={infoButton.onClick} disabled={infoButton.disabled}>
              {formatMessage({ id: 'viewStoreDetails' })}
            </ViewDetailsLink>
          )}

          {enableLoyaltyTags && (
            <LoyaltyTagsContainer>
              {inStoreLoyalty && (
                <LoyaltyTag mobile={false}>
                  <StyledLoyaltyIcon />
                  {formatMessage({ id: 'loyaltyStoreLocatorInStore' })}
                </LoyaltyTag>
              )}

              {mobileLoyalty && (
                <LoyaltyTag mobile={true}>
                  <StyledLoyaltyIcon />
                  {formatMessage({ id: 'loyaltyStoreLocatorMobile' })}
                </LoyaltyTag>
              )}
            </LoyaltyTagsContainer>
          )}
        </Container>

        <Flex alignItems="center">
          {offersButton && (
            <SecondaryButton
              data-testid="store-action-button-offers"
              onClick={offersButton.onClick}
              disabled={offersButton.disabled}
              size={isMobile ? 'small' : 'large'}
              loading={offersButton.loading}
            >
              {formatMessage({ id: 'offers' })}
            </SecondaryButton>
          )}

          {orderButton && (
            <PrimaryButton
              data-testid="store-action-button-order"
              onClick={getOrderOption}
              disabled={isOrderActionDisabled}
              size={isMobile ? 'small' : 'large'}
              loading={isOrderActionLoading}
            >
              {formatMessage({ id: 'select' })}
            </PrimaryButton>
          )}
        </Flex>
      </Flex>

      {restaurant?.physicalAddress && (
        <ConfirmLocationModal
          streetName={restaurant.physicalAddress.address1 || ''}
          city={`${restaurant.physicalAddress.city}, ${convertCountryStringToIsoCountryCode2(
            restaurant.physicalAddress.country || ''
          )}`}
          onConfirm={onConfirmDistanceWarning}
          onDismiss={dismissDialog}
          isGeolocationEnabled={isGeolocationEnabled}
        />
      )}
      {errorMessage}
    </>
  );
};
