import * as React from 'react';
import { FC } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';

import { selectors, useAppSelector } from 'state/global-state';
import { useLoyaltyRewards } from 'state/loyalty/hooks/use-loyalty-rewards';
import { PointsDisplay } from 'ui-system/components/points-display';

import { RedeemRewardAction } from './redeem-reward-action';
import {
  BottomContainer,
  ButtonContainer,
  FloatingPointsIcon,
  InfoContainer,
  PointsApplied,
  Wrapper,
} from './redeem-reward.styled';
import { IRedeemRewardViewProps, RewardActions } from './types';

export const RedeemRewardView: FC<IRedeemRewardViewProps> = ({
  handleRewardClick,
  points,
  rewardPrice,
  rewardTitle,
  isRewardApplied,
  disableApplyButton,
  pointsApplied,
  itemQuantity,
  floatDirection,
  incentiveNotInMenu,
  redeemRewardLoading,
}) => {
  const loyaltyUser = useAppSelector(selectors.loyalty.selectUser);
  const { isLimitRewardRedemptionPerOrderReached } = useLoyaltyRewards(loyaltyUser);
  const displayApplyButton =
    (itemQuantity > 1 || !isRewardApplied) &&
    !isLimitRewardRedemptionPerOrderReached &&
    !rewardPrice;
  const displayActionButtons = !redeemRewardLoading && !incentiveNotInMenu;

  const renderRewardActionButtons = () => (
    <>
      {displayApplyButton && (
        <RedeemRewardAction
          disabled={disableApplyButton}
          action={RewardActions.ADD}
          handleRewardClick={handleRewardClick}
          points={points}
        />
      )}
    </>
  );

  return (
    <Wrapper>
      <InfoContainer>
        <VisuallyHidden>{rewardTitle}</VisuallyHidden>
        <BottomContainer>
          <ButtonContainer>{displayActionButtons && renderRewardActionButtons()}</ButtonContainer>
          {!!pointsApplied && (
            <PointsApplied data-testid="points-applied" arePointsApplied={!!pointsApplied}>
              <PointsDisplay value={`- ${pointsApplied}`} />
              {floatDirection && (
                <FloatingPointsIcon key={pointsApplied} $floatDirection={floatDirection} />
              )}
            </PointsApplied>
          )}
        </BottomContainer>
      </InfoContainer>
    </Wrapper>
  );
};
