import styled from 'styled-components';

import { ActionButton } from 'components/action-button';
import { brandFont } from 'styles/brand-font';
import { primitive } from 'styles/constants/primitives';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: ${Styles.borderRadius};
  margin: ${primitive.$spacing4};
  background: ${primitive.$white};
  padding-block-start: 1rem;
  padding-block-end: 1.5rem;
  padding-inline: 1rem;
  gap: 1.5rem;
`;

export const IconContainer = styled.div`
  position: absolute;
  inset-block-start: ${primitive.$spacing5};
  inset-inline-end: ${primitive.$spacing5};
`;

export const Title = styled.p`
  text-align: center;
  font: ${brandFont.headerTwo};
  margin-block-start: 0.5rem;
  margin-block-end: 0;
  margin-inline: 2rem;
`;

export const Code = styled.div`
  display: flex;
  font: ${brandFont.hero};
  font-size: 1.875rem;
  line-height: 2rem;
  margin: 0;
  background: ${Styles.color.background};
  min-width: 10rem;
  min-height: 4rem;
  border-radius: 0.625rem;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
`;

export const GenerateButton = styled(ActionButton)`
  flex: 1;
  font-size: 0.95rem;
  padding: 0.75rem 1rem;
  background-color: ${primitive.$white};
`;
