import { IBrandRoutes } from './types';
import { createLazyRoutes } from './utils';

const LazyRoutes = createLazyRoutes({
  TrendingPage: () => import('components/trending-page'),
  PreferencesModalBkDe: () => import('components/preferences-modal-bkde'),
  IframeCampaign: () => import('components/iframe-campaign/iframe-campaign'),
});

export const brandRoutes: IBrandRoutes = {
  'routes.trending': LazyRoutes.TrendingPage,
  'routes.userPreferencesModal': LazyRoutes.PreferencesModalBkDe,
  'routes.campaign': LazyRoutes.IframeCampaign,
};
