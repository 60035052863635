import React, { FC, ReactNode } from 'react';

import { Link } from 'react-router';

import { SanityBlockContentLinkProps } from './types';

/**
 * This is a useful override for removing unwanted component hierarchy.
 * E.g. serializers.marks.em by default adds an <em /> wrapper around text content.
 * If you want to only render the container component and text, override
 * the serializers where serializers.marks.em = PassthroughComponent
 */
export const PassthroughComponent: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;

/**
 * This function return a <Link /> (internal link) or a <a /> (external link) depending on the type of href added in sanity
 */
export const formatSanityLink = ({ children, mark, ...rest }: SanityBlockContentLinkProps) => {
  return mark?.href ? (
    <Link
      to={mark.href}
      target={mark?.blank ? '_blank' : undefined}
      rel={mark?.blank ? 'noreferrer' : undefined}
      data-testid="sanity-link-to"
      {...rest}
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};
