import { Link as BaseInternalLink } from 'react-router';
import styled, { css } from 'styled-components';

import { ExternalLink as BaseExternalLink } from 'components/external-link';

const linkStyles = css`
  margin: 0;

  color: ${Styles.color.grey.four};
  font-size: 0.9375rem;
  font-family: ${Styles.fontFamily.body};
  text-align: start;
  text-transform: none;
  text-decoration: none;
  line-height: 1.6;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${Styles.color.grey.four};
  }

  /* Override global outline color here for contrast ratio */
  &:focus {
    outline-width: 1px;
    outline-color: ${Styles.color.grey.four};
  }
`;

export const InternalLink = styled(BaseInternalLink)`
  ${linkStyles}
`;

export const ExternalLink = styled(BaseExternalLink)`
  ${linkStyles}
`;

export const KoreaPolicyLink = styled(BaseExternalLink)`
  ${linkStyles}
  color:${Styles.color.footerPolicyText};
`;

const horizontalLinkStyles = css`
  font-size: 0.9375rem;
  line-height: 1;
`;

export const HorizontalInternalLink = styled(InternalLink)`
  ${horizontalLinkStyles}
`;

export const HorizontalExternalLink = styled(ExternalLink)`
  ${horizontalLinkStyles}
`;

const highlightedLinkStyles = css`
  color: ${Styles.color.primary};

  &:hover {
    color: ${Styles.color.primary};
  }
`;

export const HighlightedInternalLink = styled(InternalLink)`
  ${highlightedLinkStyles}
`;

export const HighlightedExternalLink = styled(ExternalLink)`
  ${highlightedLinkStyles}
`;

const paddedLinkStyles = css`
  /* BK currently doesn't have a notion of a padded link, but if they did, this is where it would go */
`;

export const PaddedInternalLink = styled(InternalLink)`
  ${paddedLinkStyles}
`;

export const PaddedExternalLink = styled(ExternalLink)`
  ${paddedLinkStyles}
`;
