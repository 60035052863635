import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';
import { Link } from 'react-router';
import styled from 'styled-components';

import { ClickableContainer } from 'components/clickable-container';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { useLocationContext } from 'state/location';
import { portalToHeader } from 'state/mobile-header-nav';
import { primitive } from 'styles/constants/primitives';

const ContainerNoDecoration = styled(ClickableContainer)`
  margin: ${primitive.$spacing3};
  border-radius: 100%;
  z-index: ${Styles.zIndex.overlay};

  &::before {
    color: transparent;
  }

  &:active,
  &:focus {
    background-color: ${p => p.theme.token('background-icon-header-contrast')};
  }

  &:hover,
  &:link,
  &:visited {
    color: inherit;
  }
`;

const LinkNoDecoration = styled(Link)`
  margin: ${primitive.$spacing3} ${primitive.$spacing6};
  position: fixed;
  border-radius: 100%;
  z-index: ${Styles.zIndex.overlay};
  inset-block-start: calc(3px + env(safe-area-inset-top));
  inset-inline-start: 3px;

  &::before {
    color: transparent;
  }

  &:active,
  &:focus {
    background-color: ${p => p.theme.token('background-icon-header-contrast')};
  }

  &:hover,
  &:link,
  &:visited {
    color: inherit;
  }
`;

export const BackArrowLink = portalToHeader(({ to }: { to: string }) => {
  const { formatMessage } = useIntl();
  const cdp = useCdpContext();
  const { location } = useLocationContext();

  const onClickLogBackEvent = () => {
    cdp.logNavigationClick(CustomEventNames.BUTTON_CLICK, {
      Name: 'Back',
      PathFrom: location.pathname,
      PathTo: to,
    });
  };

  return (
    <LinkNoDecoration to={to} onClick={onClickLogBackEvent}>
      <Icon icon="back" color="icon-header-contrast" width="24px" aria-hidden />
      <VisuallyHidden>{formatMessage({ id: 'back' })}</VisuallyHidden>
    </LinkNoDecoration>
  );
});

export const BackArrowHistory: React.FC<{
  className?: string;
  onClick?: VoidFunction;
  icon?: React.ReactNode;
}> = portalToHeader(
  ({
    className,
    onClick = () => window.history.back(),
    icon = <Icon icon="back" color="icon-header-contrast" width="24px" aria-hidden />,
  }) => {
    const { formatMessage } = useIntl();

    return (
      <ContainerNoDecoration
        className={className}
        data-testid="back-arrow-history"
        onClick={onClick}
      >
        {icon}
        <VisuallyHidden>{formatMessage({ id: 'back' })}</VisuallyHidden>
      </ContainerNoDecoration>
    );
  }
);
