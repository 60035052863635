import { ISanityBlockContent, ISanityLocaleBlockContent } from '@rbi-ctg/menu';
import { ServiceMode } from 'generated/graphql-gateway';

export const getFirstStringInLocaleBlockContent = (
  localeBlockContent: ISanityLocaleBlockContent | undefined | null
): string => localeBlockContent?.localeRaw?.[0]?.children?.[0]?.text || '';

// TODO: This method is not handling line breaks
export const blockContentToPlainText = (contentBlocks: ISanityBlockContent[] = []): string =>
  (contentBlocks ?? [])
    .map(block => {
      // if it's not a text block with children,
      // return an empty string
      if (block._type !== 'block' || !block.children) {
        return '';
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(({ text }) => text).join('');
    })
    .join(' ')
    .trim();

export const serviceModeToSanityHoursMap = {
  [ServiceMode.DELIVERY]: 'deliveryHours',
  [ServiceMode.DRIVE_THRU]: 'driveThruHours',
  [ServiceMode.CURBSIDE]: 'curbsideHours',
  [ServiceMode.EAT_IN]: 'diningRoomHours',
} as const satisfies Partial<Record<ServiceMode, string>>;

export const isLocaleBlockTextWidget = (
  widget: any
): widget is { readonly __typename?: 'LocaleBlockTextWidget' } =>
  widget.__typename === 'LocaleBlockTextWidget';
