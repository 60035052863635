import React, { FC, ReactNode } from 'react';

import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';
import { View } from 'components/view';
import { useMediaQuery } from 'hooks/use-media-query';
import { useMobileFooterComponent } from 'state/mobile-footer';

import { useLayoutContext } from './layout-context';

export interface LayoutBottomContainerProps extends IBaseProps {}

const Container = styled(View)`
  width: 100%;
`;

export const LayoutBottomContainer: FC<{ children?: ReactNode }> = ({ children, ...props }) => {
  const isDesktop = useMediaQuery('desktopLarge');
  const FooterComponent = useMobileFooterComponent();
  const { showBottomTabs } = useLayoutContext();

  if (!showBottomTabs) {
    return null;
  }

  if (FooterComponent && !isDesktop) {
    return <FooterComponent />;
  }

  return (
    <Container data-testid="container-layout-bottom" {...props}>
      {children}
    </Container>
  );
};
