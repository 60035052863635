import React from 'react';

import { OnClick } from '@rbilabs/components-library/build/components/button';
import { useIntl } from 'react-intl';

import { FavoriteIcon } from 'components/favorite-icon';
import { ButtonForIconFav } from 'components/favorite-icon/styled';
import { Animation } from 'components/lottie-animations/favorite-loading';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { IconWrapper, LoadingWrapper } from './store-card-actions.styled';
import { StoreCardButton } from './store-card-button';
import { StoreCardIcon } from './store-card-icon';

interface StoreCardActionsProps {
  onDiagnosticsClick?: OnClick;
  onFavoritesClick?: OnClick;
  favoriteIcon: boolean;
  isUpdatingFavorites?: boolean;
}

export const StoreCardActions: React.FC<StoreCardActionsProps> = ({
  onDiagnosticsClick,
  onFavoritesClick,
  favoriteIcon = false,
  isUpdatingFavorites,
}) => {
  const { formatMessage } = useIntl();

  const enableFavorites = useFlag(LaunchDarklyFlag.ENABLE_FAVORITE_STORES);

  const FavIcon = <FavoriteIcon isFavorite={favoriteIcon} />;

  return (
    <>
      {onDiagnosticsClick && (
        <IconWrapper>
          <StoreCardButton
            data-testid="store-favorite-button"
            aria-label="Open a diagnostic modal for this store"
            color="icon-default"
            onClick={onDiagnosticsClick}
            startIcon={<StoreCardIcon color="icon-default" icon="settings" />}
          />
        </IconWrapper>
      )}

      {enableFavorites && (
        <IconWrapper>
          <ButtonForIconFav
            data-testid={favoriteIcon ? 'fav-icon-selected' : 'fav-icon'}
            onClick={onFavoritesClick}
            aria-label={formatMessage({
              id: favoriteIcon ? 'removeRestaurantFromFavorites' : 'setRestaurantAsFavorite',
            })}
            isTransparent={true}
          >
            {isUpdatingFavorites ? (
              <LoadingWrapper>
                <Animation />
              </LoadingWrapper>
            ) : (
              FavIcon
            )}
          </ButtonForIconFav>
        </IconWrapper>
      )}
    </>
  );
};
