import styled from 'styled-components';

import { ActionButton } from 'components/action-button';
import { IconRefresh } from 'components/icons/refresh';
import { QRCode } from 'components/qrcode';
import { UnstyledButton } from 'components/unstyled-button';
import { brandFont } from 'styles/brand-font';
import { primitive } from 'styles/constants/primitives';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-block-end: 2rem;
  margin-inline-start: 0;
  margin-inline-end: auto;
  > * {
    margin-block-end: 1rem;
  }

  ${Styles.desktop} {
    max-width: 36rem;
    margin-inline: auto;
  }
`;

export const ShortCodeContent = styled.div`
  margin-block-start: 1.3rem;
  min-height: 6.125rem;
  width: auto;
  min-width: 15.625rem;
  font-family: ${Styles.fontFamily.brand};
  border-radius: ${Styles.borderRadius};
  background-color: ${Styles.color.black};
  color: ${Styles.color.white};
  font-size: 3.5rem;
  text-align: center;
  line-height: 4.125rem;
  padding: 1rem 1.5rem;
`;

export const StyledRefreshIcon = styled(IconRefresh)`
  fill: ${Styles.color.black};
`;

export const QRCodeWrapper = styled.div`
  width: 100%;

  div:last-child {
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
  }
`;

export const QRCodeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  background-color: ${Styles.color.black};
  min-height: 16rem;

  canvas {
    margin-block-end: 0;
  }

  & > button {
    display: flex;
    font-size: 1.125rem;
    margin-block-start: 4rem;
    margin-block-end: 0;
    margin-inline: auto;
    padding: 0.7rem 2rem;
    background-color: ${primitive.$white};
    color: ${Styles.color.black};

    svg {
      transform: rotate(50deg);
      width: 0.875rem;
      margin-inline-end: 0.25rem;
    }
  }
`;

export const QRCodeTitle = styled.h2`
  margin: 0;
  color: ${Styles.color.white};
  font-family: ${Styles.fontFamily.brand};
  font-size: 1.5rem;
`;

export const QRCodeSubtitle = styled.h3`
  margin: 0;
  margin-block-end: 1rem;
  font-size: 0.9rem;
  color: ${Styles.color.white};
`;

export const StyledQRCode = styled(QRCode)`
  margin-block-end: 3rem;
  max-width: 16rem;
  max-height: 16rem;
  border-radius: 0.625rem;
`;

export const SkeletonContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`;

export const SkeletonBox = styled.div`
  text-align: center;
`;

export const RefreshExpiredCode = styled.div`
  height: 35vh;
  margin-block-start: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Styles.color.tertiary};
`;

export const RefreshExpiredCodeTitle = styled.h2`
  font: ${brandFont.headerTwo};
  margin: 0;
`;

export const RefreshExpiredCodeSubtitle = styled.p`
  font: ${brandFont.copyOne};
  font-size: 1rem;
  margin: 10px 0;
`;

export const StyledRefreshButton = styled(ActionButton)`
  background: ${primitive.$white};
  font: ${brandFont.headerThree};
  font-size: 1rem;
  margin-block-start: 16px;
`;

export const GenerateNewCode = styled(IconRefresh)`
  fill: ${Styles.color.primary};
`;

export const ShortCodeContainerV3 = styled.div`
  background: #f9f5ed;
  padding-block-start: 1.5rem;
  padding-block-end: 0.5rem;
  padding-inline: 1.5rem;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 1rem;
  border-end-start-radius: 1rem;
  text-align: center;
`;

export const ShortCodeSubtitleV3 = styled.div`
  font-size: 1rem;
`;

export const ShortCodeContentV3 = styled(ShortCodeContent)`
  margin: 0;
  background: none;
  color: inherit;
  font-size: 2rem;
  padding: 0;
  min-height: unset;
`;

export const SkeletonContainerV3 = styled(SkeletonContainer)`
  margin: 0 auto;
`;

export const SkeletonBoxV3 = styled.div`
  text-align: center;
  line-height: 2.125rem;
`;

export const PageHeader = styled.div`
  display: flex;
  padding-block: 1.125rem;
  padding-inline-start: 1rem;
  padding-inline-end: 3rem;
  margin-block-end: 0.5rem;
  margin-block-start: env(safe-area-inset-top);
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  box-shadow: ${Styles.boxShadow};
  clip-path: inset(0px -10px -10px -10px);
`;

export const PageName = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  flex: 1 0 0;
  text-align: center;
  font-family: ${Styles.fontFamily.base};
`;

export const BackButton = styled(UnstyledButton)`
  width: 1.5rem;
  height: 1.5rem;
`;
