import React from 'react';

import { Link } from 'react-router';
import styled from 'styled-components';

import { RawPicture } from 'components/raw-picture';
import { IImageWidgetFragment } from 'generated/sanity-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

interface IBrandLogoProps extends IImageWidgetFragment {
  className?: string;
}

const ImageWidgetLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

const BrandPicture = ({ className, image, imageDescription }: IBrandLogoProps) => {
  return image?.locale ? (
    <RawPicture image={image?.locale} alt={imageDescription?.locale ?? ''} className={className} />
  ) : null;
};

const LinkedBrandLogo = (props: IBrandLogoProps) => {
  const { attributionLink } = props;

  return attributionLink ? (
    <ImageWidgetLink to={attributionLink} target="_blank">
      <BrandPicture {...props} />
    </ImageWidgetLink>
  ) : (
    <BrandPicture {...props} />
  );
};

export const BrandLogo = (props: IBrandLogoProps) => {
  const enableClickableImageWidget = useFlag(LaunchDarklyFlag.ENABLE_CLICKABLE_IMAGES);

  return enableClickableImageWidget ? <LinkedBrandLogo {...props} /> : <BrandPicture {...props} />;
};
