import { useCallback } from 'react';

import { isNil } from 'lodash';

import { Refinement } from '@rbi-ctg/frontend';
import {
  ICartEntry,
  ICombo,
  IComboSlot,
  IComboSlotOption,
  IComboSlotSelection,
  IItem,
  IItemOption,
  IItemOptionModifier,
  IModifierSelection,
  IQuantityBasedPlu,
  ISanityItemOption,
  IWithPricingProps,
  PremiumComboSlotPricingVarations,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { propIs } from 'utils';
import { CartEntryType } from 'utils/cart/types';
import { isLocalDev } from 'utils/environment';
import { logger } from 'utils/logger';
import { assignInclusionComboSlotSelections, isCombo, isItem } from 'utils/menu';
import { ModifierComponentStyle } from 'utils/menu/modifiers';
import {
  IComboSlotSelectionsNoDataInSelections,
  isComboSlotSelectionPremium,
  isOptionInComboSlot,
  priceForItemOptionModifierBooleanOrStepper,
  priceForItemOptionModifierSelection,
} from 'utils/menu/price';
import {
  IWithVendorConfig,
  PluTypes,
  bestPlusForQuantity,
  computeCompositeComboSlotPlu,
  concatenateSizePlu,
  getConstantPlu,
  getVendorConfig,
} from 'utils/vendor-config';
import { getDefaultSelectionsForComboSlot } from 'utils/wizard';

export type ISelectionsIncluded = IComboSlotSelection & {
  notIncluded?: number;
};

export interface IPriceItemOptionModifierInput {
  item: ICombo | IItem | ICartEntry | null;
  itemOption: ISanityItemOption | IItemOption | null;
  modifier: (IItemOptionModifier & { quantity?: number }) | null;
}

export interface IPriceComboSlotSelection {
  combo: ICombo;
  comboSlot: IComboSlot;
  selectedOption?: IComboSlotSelection['option'];
  pricingMethod?: PremiumComboSlotPricingVarations;
}

export interface IPriceItemInComboSlotSelection {
  combo: ICombo;
  comboSlot: IComboSlot;
  pricingMethod?: PremiumComboSlotPricingVarations;
  selectedItem: IItem;
}

export type PriceComboSlotSelectionFunction = ({
  combo,
  comboSlot,
  selectedOption,
  pricingMethod,
}: IPriceComboSlotSelection) => number;
interface IUsePricingFunctionProps extends IWithPricingProps {}

type IsCartEntryType<MenuType extends { type: CartEntryType }> = Refinement<object, MenuType>;
const isCartEntryCombo = () => propIs<ICartEntry>('type', CartEntryType.combo);

const isCartEntryWithTypeCombo = isCartEntryCombo() as IsCartEntryType<ICartEntry>;

export const usePricingFunction = ({ prices, vendor }: IUsePricingFunctionProps) => {
  const premiumComboSlotPricingMethod = useFlag<PremiumComboSlotPricingVarations>(
    LaunchDarklyFlag.ENABLE_PREMIUM_COMBO_SLOTS
  );
  /**
   * hack - this is an attempt to reconcile bad data in
   * the ui layer - there is a chance that injection will
   * fail if the price for a plu is not found, but this
   * is a temporary fix to display a derived price.
   * if minPlu.quantity is not 1 this may be a bad price.
   * it will only be used for display - orders must be validated
   * and priced before injection.
   **/
  const priceForQuantityPlu = useCallback(
    (item: IWithVendorConfig, quantity: number = 1) => {
      const vendorConfig = getVendorConfig(item, vendor) as IQuantityBasedPlu;
      if (!vendorConfig || !prices) {
        return 0;
      }
      const plus = bestPlusForQuantity(vendorConfig.quantityBasedPlu, prices, quantity);
      if (!plus.length) {
        return 0;
      }
      return plus.reduce((acc, { plu }) => acc + prices[plu], 0);
    },
    [prices, vendor]
  );

  const priceForItemOptionModifier = useCallback(
    ({ item, itemOption, modifier }: IPriceItemOptionModifierInput): number => {
      if (!item || !itemOption || !modifier || isCombo(item)) {
        return 0;
      }

      if (
        !itemOption.componentStyle ||
        itemOption.componentStyle === ModifierComponentStyle.Selector
      ) {
        return priceForItemOptionModifierSelection({
          item,
          itemOption,
          modifier,
          prices,
          vendor,
        });
      }

      return priceForItemOptionModifierBooleanOrStepper({
        item,
        itemOption,
        modifier,
        prices,
        vendor,
      });
    },
    [prices, vendor]
  );

  const getPriceForPlus = useCallback(
    (plu: string, mainItemPlu: string | null = null): number => {
      const combinedPlu = `${plu}-${mainItemPlu}`;

      if (mainItemPlu && prices && !isNil(prices[combinedPlu])) {
        return prices[combinedPlu];
      }

      return prices?.[plu] || 0;
    },
    [prices]
  );

  /**
   * Returns the price of a menu object without any selections
   */
  const pricingFunctionNoSelections = useCallback(
    (item: IWithVendorConfig, quantity?: number): number => {
      const vendorConfig = getVendorConfig(item, vendor);
      if (!vendorConfig || vendorConfig.pluType === PluTypes.IGNORE) {
        return 0;
      }

      // for quantity based plus: if price exists for plu, use it -
      // otherwise compute best price for quantity selected from
      // available plus
      // Note: if quantity is undefined we should allow
      // `priceForQuantityPlu` to find the minimum quantity
      // plu to use for pricing, so we don't default to 1
      // until after this check
      if (vendorConfig.pluType === PluTypes.QUANTITY) {
        return priceForQuantityPlu(item, quantity);
      }

      const itemQuantity = quantity || 1;

      /**
       * If item is a combo, and there is a main item with a constant plu,
       * we want to check if there exists a price `<comboPlu>-<mainItemPlu>`,
       * and if it does, use that price instead of `<comboPlu>`
       */

      const mainItemComboPlu =
        isCombo(item) && item.mainItem
          ? getConstantPlu(getVendorConfig(item.mainItem, vendor))
          : null;

      /**
       * This is due to repricing of cart entries.
       * The correct way would be to reprice everything based on requests from sanity,
       * and not count exclusively with the data from local storage.
       * This should be temporary fix.
       */
      const mainItemCartEntry =
        isCartEntryWithTypeCombo(item) &&
        item.cartId &&
        item.children.find(entry => entry.type === 'Item');

      const mainItemCartEntryPlu = mainItemCartEntry
        ? getConstantPlu(getVendorConfig(mainItemCartEntry, vendor))
        : null;

      const mainItemPlu = mainItemComboPlu || mainItemCartEntryPlu;

      if (vendorConfig.pluType === PluTypes.MULTI_CONSTANT) {
        return (
          (vendorConfig.multiConstantPlus || []).reduce(
            (acc, { plu }) => acc + getPriceForPlus(plu, mainItemPlu),
            0
          ) * itemQuantity
        );
      }

      let price = 0;
      if (vendorConfig.pluType === PluTypes.CONSTANT) {
        price = getPriceForPlus(vendorConfig.constantPlu, mainItemPlu);
      }

      if (vendorConfig.pluType === PluTypes.SIZE_BASED) {
        const plu = concatenateSizePlu(vendorConfig.sizeBasedPlu);
        price = plu ? getPriceForPlus(plu, mainItemPlu) : 0;
      }

      if (vendorConfig.pluType === PluTypes.PARENT_CHILD) {
        price =
          getPriceForPlus(vendorConfig.parentChildPlu.plu, mainItemPlu) +
          getPriceForPlus(vendorConfig.parentChildPlu.childPlu, mainItemPlu);
      }

      return price * itemQuantity;
    },
    [getPriceForPlus, priceForQuantityPlu, vendor]
  );

  const priceForItemModifierSelections = useCallback(
    (
      item: IItem | null,
      quantity: number = 1,
      modifierSelections: Array<IModifierSelection> = []
    ): number => {
      if (!isItem(item)) {
        return 0;
      }

      return (
        (modifierSelections as Array<IModifierSelection>).reduce<number>(
          (price, { _key, modifier }) => {
            const itemOption = (item.options || []).find(
              ({ _key: itemOptionKey }) => _key === itemOptionKey
            );

            // illegal case, modifier selection
            // does not correspond to this item's
            // options. discard it when pricing
            if (!itemOption) {
              logger.warn({
                itemId: item._id,
                message: 'No ItemOption found for modifier',
                modifier,
              });
              return price;
            }

            const modifierPrice = priceForItemOptionModifier({ item, itemOption, modifier });

            return price + modifierPrice;
          },
          0
        ) * quantity
      );
    },
    [priceForItemOptionModifier]
  );

  const priceForPremiumComboSlotComposite = useCallback(
    (combo: ICombo, selectedItem: IItem) => {
      const compositePlu = computeCompositeComboSlotPlu({
        parent: combo,
        child: selectedItem,
        vendor,
      });
      return compositePlu ? (prices?.[compositePlu] ?? 0) : 0;
    },
    [prices, vendor]
  );

  const priceForPremiumComboSlotDelta = useCallback(
    (comboSlot: IComboSlot, selectedItem: IItem) => {
      const firstDefaultSelection = getDefaultSelectionsForComboSlot(comboSlot)[0];

      // get price of default option
      const defaultPrice = firstDefaultSelection?.option
        ? pricingFunctionNoSelections(firstDefaultSelection?.option?.option)
        : 0;
      // subtract the price of the default from the premiumOption
      const delta = pricingFunctionNoSelections(selectedItem) - defaultPrice;
      // If delta is negative the price is zero
      return delta > 0 ? delta : 0;
    },
    [pricingFunctionNoSelections]
  );

  const priceForItemInComboSlotSelection = useCallback(
    ({
      combo,
      comboSlot,
      selectedItem,
      // This allows you to override the method if needed
      // If you do not want to use the value given by the flag pass in your own method
      pricingMethod = 'none',
    }: IPriceItemInComboSlotSelection) => {
      const overrideMethod =
        pricingMethod === 'none' ? (premiumComboSlotPricingMethod ?? 'none') : pricingMethod;

      switch (overrideMethod) {
        case 'composite':
          return priceForPremiumComboSlotComposite(combo, selectedItem);

        case 'delta':
          return priceForPremiumComboSlotDelta(comboSlot, selectedItem);

        case 'direct':
          return pricingFunctionNoSelections(selectedItem);

        case 'none':
          return 0;

        default: {
          if (isLocalDev) {
            logger.warn({
              message: '[priceForComboSlotSelection] You are using an unrecognized pricingMethod',
              pricingMethod: overrideMethod,
            });
          }
          return 0;
        }
      }
    },
    [
      premiumComboSlotPricingMethod,
      priceForPremiumComboSlotComposite,
      priceForPremiumComboSlotDelta,
      pricingFunctionNoSelections,
    ]
  );

  const priceForComboSlotSelection = useCallback(
    ({
      combo,
      comboSlot,
      selectedOption,
      // This allows you to override the method if needed
      // If you do not want to use the value given by the flag pass in your own method
      pricingMethod = 'none',
    }: IPriceComboSlotSelection) => {
      // return instantly if the selectedOption doesn't exist in the comboSlot... Returning zero. Its on the caller to identify what to do.
      if (!isOptionInComboSlot(comboSlot, selectedOption)) {
        return 0;
      }
      // ComboSlots are always free unless they are explicitely marked as premium
      if (!selectedOption || !isComboSlotSelectionPremium(selectedOption)) {
        return 0;
      }
      const selectedItem = selectedOption?.option;

      if (!selectedItem || selectedItem._type === MenuObjectTypes.PICKER) {
        return 0;
      }
      return priceForItemInComboSlotSelection({
        combo,
        comboSlot,
        pricingMethod,
        selectedItem,
      });
    },
    [priceForItemInComboSlotSelection]
  );

  const priceForComboSelections = useCallback(
    (
      combo: ICombo | null,
      quantity: number = 1,
      modifierSelections: Array<IModifierSelection> = [],
      comboSlotSelections: IComboSlotSelectionsNoDataInSelections = {}
    ): number => {
      if (!isCombo(combo)) {
        return 0;
      }

      const mainItemModsTotal: number = priceForItemModifierSelections(
        combo.mainItem,
        1,
        (modifierSelections || []).filter(({ comboSlotId }) => !comboSlotId)
      );

      // price upsell combo slot items and combo slot modifiers
      const comboSlotTotal = combo.options?.reduce<number>((acc, comboSlot) => {
        const { selections } = comboSlotSelections[comboSlot._id] || {};

        if (!selections) {
          return acc;
        }

        const assignedSelections: ISelectionsIncluded[] = assignInclusionComboSlotSelections(
          selections,
          comboSlot.maxAmount
        );

        const comboTotals: number = assignedSelections.reduce<number>((total, sel) => {
          const premiumPrice = sel.option.isPremium
            ? priceForComboSlotSelection({
                combo,
                comboSlot,
                selectedOption: selections.find(
                  (selection: { option: IComboSlotOption }) =>
                    selection.option.option._id === sel.option.option._id
                )?.option,
              })
            : 0;

          // the price for all selections not included in the combo (not part of the combo, went over comboSlots max)
          const upsellItemPrice = sel.notIncluded
            ? pricingFunctionNoSelections(sel.option.option, sel.notIncluded)
            : premiumPrice;
          // the price for the selected modifiers
          const modifiersPrice = isItem(sel.option.option)
            ? priceForItemModifierSelections(
                sel.option.option,
                1,
                (modifierSelections || []).filter(
                  ({ comboSlotId }) => comboSlotId === comboSlot._id
                )
              )
            : 0;

          return total + (upsellItemPrice + modifiersPrice) * sel.quantity;
        }, 0);

        return acc + comboTotals;
      }, 0);

      return (mainItemModsTotal + comboSlotTotal) * quantity;
    },
    [priceForComboSlotSelection, priceForItemModifierSelections, pricingFunctionNoSelections]
  );

  const priceForModifierSelections = useCallback(
    (
      item: IWithVendorConfig,
      quantity: number = 1,
      modifierSelections: Array<IModifierSelection> = [],
      comboSlotSelections: IComboSlotSelectionsNoDataInSelections = {}
    ): number => {
      if (isItem(item)) {
        return priceForItemModifierSelections(item, quantity, modifierSelections);
      }

      if (isCombo(item)) {
        return priceForComboSelections(item, quantity, modifierSelections, comboSlotSelections);
      }

      return 0;
    },
    [priceForComboSelections, priceForItemModifierSelections]
  );

  /**
   * Returns the price of a menu object
   */
  const pricingFunction = useCallback(
    (
      item: IWithVendorConfig,
      quantity: number = 1,
      modifierSelections: Array<IModifierSelection> = [],
      comboSlotSelections: IComboSlotSelectionsNoDataInSelections = {}
    ): number => {
      const itemQuantity = quantity || 1;
      const price = pricingFunctionNoSelections(item, itemQuantity);
      if (!isItem(item) && !isCombo(item)) {
        return price;
      }

      return (
        price +
        priceForModifierSelections(item, itemQuantity, modifierSelections, comboSlotSelections)
      );
    },
    [priceForModifierSelections, pricingFunctionNoSelections]
  );

  return {
    priceForItemOptionModifier,
    priceForComboSlotSelection,
    pricingFunction,
    priceForItemInComboSlotSelection,
  };
};
