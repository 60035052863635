import React, { isValidElement } from 'react';

import { IconLoyaltyPoints } from 'components/icons/loyalty-points-icon';
import { IStyleable } from 'ui-system/types';

import {
  PointsDisplayIcon,
  PointsDisplayPrefix,
  PointsDisplaySuffix,
  PointsDisplayValue,
  Wrapper,
} from './points-display.styled';

export interface IPointsDisplayProps extends IStyleable {
  value?: number | string | JSX.Element | null;
  prefix?: string;
  suffix?: string;
  icon?: JSX.Element | null;
}

export const PointsDisplay: React.FC<
  IPointsDisplayProps & React.ComponentPropsWithoutRef<'div'>
> = ({ className, value, prefix = '', suffix = '', icon = <IconLoyaltyPoints />, ...rest }) => {
  const valueDisplay = isValidElement(value) ? (
    value
  ) : (
    <PointsDisplayValue>{value || 0}</PointsDisplayValue>
  );
  return (
    <Wrapper className={className} dir={'ltr'} {...rest}>
      {prefix && <PointsDisplayPrefix>{`${prefix}`}</PointsDisplayPrefix>}
      {icon && <PointsDisplayIcon>{icon}</PointsDisplayIcon>}
      {valueDisplay}
      {suffix && <PointsDisplaySuffix>{`${suffix}`}</PointsDisplaySuffix>}
    </Wrapper>
  );
};
