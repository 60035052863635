import React from 'react';

import { Link } from 'react-router';
import styled from 'styled-components';

interface IHeroLinkProps {
  to: string;
  fullWidth?: boolean;
  [key: string]: any;
}

const UnStyledLink = styled(Link)<IHeroLinkProps>`
  display: flex;
  align-items: center;
  width: ${p => (p.fullWidth ? '100%' : 'auto')};
  text-decoration: none;
`;

export const HeroLink: React.FC<IHeroLinkProps> = ({
  to,
  fullWidth = false,
  children,
  ...rest
}) => (
  <UnStyledLink fullWidth={fullWidth} to={to} {...rest}>
    {children}
  </UnStyledLink>
);
