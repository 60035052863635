import { getApiKey } from 'utils/environment';
import { getCurrentVersion } from 'utils/live-updates';

import { getSessionReplayPlugin } from './session-replay-cdn';

declare global {
  interface Window {
    sessionReplay: {
      plugin: Function;
    };
    amplitude: boolean;
  }
}

export const initSdk = (callback: () => void, sampleRateValue: number = 0) => {
  getCurrentVersion().then(async () => {
    const amplitude = await import('@amplitude/analytics-browser');
    await getSessionReplayPlugin();
    const sessionReplayTracking = window.sessionReplay?.plugin({
      sampleRate: sampleRateValue,
    });
    if (sessionReplayTracking) {
      amplitude.add(sessionReplayTracking);
    }
    await amplitude.init(getApiKey('amplitudeCdpKey'), undefined, {
      serverZone: getApiKey('amplitudeCdpRegion'),
      autocapture: {
        attribution: true,
        pageViews: false,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false,
      },
    });
    window.amplitude = true;
    callback();
  });
};
