import React from 'react';

import styled from 'styled-components';

import { ModalHeading } from 'components/modal';

import { IStoreDiagnosticData } from './internal-store-diagnostic-modal';
import { ContentContainer } from './styled';
import { theme } from './theme';

const CodeWrapper = styled.div`
  background-color: ${Styles.color.codeBlockBackground};
  color: ${theme.codeColor};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.23rem 0.5rem;
  margin: 0;
  width: 100%;
  font-size: 1.23rem;
  font-family: monospace;
  ${Styles.desktop} {
    padding: 0.23rem 1rem;
  }
`;

const Divider = styled.hr`
  margin-block-start: 1.4rem;
  width: 100%;
`;

const StyledModalHeading = styled(ModalHeading)`
  margin-block-end: 1.5rem;
  ${Styles.desktop} {
    max-width: none;
  }
`;

const StyledModalSubHeading = styled.h4`
  margin-block-start: 0;
  margin-block-end: 1.5rem;
  margin-inline: 0;
  font-weight: 400;
`;

const BoldMonospace = styled.code`
  font-weight: bold;
  font-size: 1.23rem;
`;

export const determineStoreInfoSubheader = (
  storeName: string | undefined,
  storeAddress: string | undefined
) => {
  if (!storeName) {
    return `(${storeAddress})`;
  }

  if (!storeAddress || storeName.toLowerCase() === storeAddress.toLowerCase()) {
    return `(${storeName})`;
  }

  return `(${storeName} - ${storeAddress})`;
};

export const DiagnosticModalContents: React.FC<{
  storeDiagnosticData: IStoreDiagnosticData | undefined;
}> = ({ storeDiagnosticData }) => {
  if (!storeDiagnosticData) {
    return (
      <ContentContainer>
        <StyledModalHeading>No Diagnostic Data Available</StyledModalHeading>
        <p>Sorry, diagnostic data is not available at this time!</p>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <StyledModalHeading>Store Diagnostics</StyledModalHeading>
      <StyledModalSubHeading>
        {determineStoreInfoSubheader(
          storeDiagnosticData.restaurantName,
          storeDiagnosticData.restaurantStreetAddress
        )}
      </StyledModalSubHeading>
      <Divider />

      <p>
        Only pickup is supported at this time, delivery will be added soon along with
        isRestaurantOpen. Here are the current diagnostic results for pickup:
      </p>

      <CodeWrapper>{`isRestaurantOpenForPickup: ${storeDiagnosticData.pickup.isRestaurantOpen}`}</CodeWrapper>

      <Divider />

      <p>
        <BoldMonospace>isRestaurantOpenForPickup </BoldMonospace>
        is true if either of these store properties is true (here are the current values for this
        store):
      </p>

      <CodeWrapper>{`driveThruOpen: ${storeDiagnosticData.pickup.driveThruOpen}`}</CodeWrapper>
      <CodeWrapper>{`diningRoomOpen: ${storeDiagnosticData.pickup.diningRoomOpen}`}</CodeWrapper>

      <Divider />
      <p>
        <BoldMonospace>isMobileOrderingAvailable </BoldMonospace>
        is set to the value of the store's <BoldMonospace>hasMobileOrdering </BoldMonospace>
        property (here's the current value for this store):
      </p>

      <CodeWrapper>{`isMobileOrderingAvailable: ${storeDiagnosticData.isMobileOrderingAvailable}`}</CodeWrapper>
      <CodeWrapper>{`hasMobileOrdering: ${storeDiagnosticData.restaurantHasMobileOrdering}`}</CodeWrapper>

      <Divider />
      <p>
        <BoldMonospace>isRestaurantPosOnline </BoldMonospace>is determined by a store's heartbeat
        check.
      </p>

      <CodeWrapper>{`isRestaurantPosOnline: ${storeDiagnosticData.isRestaurantPosOnline}`}</CodeWrapper>
    </ContentContainer>
  );
};
