import { Link } from 'react-router';
import styled, { css } from 'styled-components';

import { MoreCircle } from './more';

export const NavLinkStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding: 0.25rem 0;
  font-size: 0.75rem;
  letter-spacing: 0.3px;
  line-height: 0;
  text-decoration: none;
  color: ${Styles.color.mobileNavButtonColor};
  fill: ${Styles.color.mobileNavButtonColor};

  &.active {
    color: ${Styles.color.secondary};
    fill: ${Styles.color.primary};
  }
  &.active ${MoreCircle} {
    background-color: ${Styles.color.primary};
  }
`;

export const StyledLink = styled(Link)`
  ${NavLinkStyles};
`;

export const NavButton = styled.button`
  ${NavLinkStyles}
`;
