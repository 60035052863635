import styled, { css } from 'styled-components';

import { IconQrCodeScanner as IconQrCodeScannerBase } from 'components/icons/qr-code-scanner';
import { IconRefillDrink as IconRefillDrinkBase } from 'components/icons/refill-drink';
import { brandFont } from 'styles/brand-font';
import { primitive } from 'styles/constants/primitives';

type IsFHSBrandProp = {
  isFHSBrand: boolean;
};

type StyledProps = IsFHSBrandProp & React.HTMLProps<HTMLParagraphElement>;

const IconMarginStyles = {
  margin: '0 1rem',
};

const BrandColor = css<StyledProps>`
  color: ${({ isFHSBrand }) => isFHSBrand && primitive.fhs.$houseNavy};
`;

export const ModalContainer = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const Overlay = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 12px;
  background-color: ${Styles.color.white};
  padding: ${primitive.$spacing6};
  max-width: 320px;
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-block-start: 1.5rem;
`;

export const Container = styled.button`
  display: flex;
  align-items: center;
  border-radius: 12px;
  background-color: ${Styles.color.white};
  min-height: 72px;
  padding: 16px 0;
  width: 100%;
  margin: 0 auto;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p<IsFHSBrandProp>`
  font: ${brandFont.headerThree};
  margin-block-start: 0;
  margin-block-end: 0.3125rem;
  margin-inline: 0;
  ${BrandColor}
`;

export const ModalTitle = styled.p<IsFHSBrandProp>`
  font: ${brandFont.headerOne};
  margin-block-start: 0;
  margin-block-end: 0.75rem;
  margin-inline: 0;
  ${BrandColor}
`;

export const Description = styled.p<IsFHSBrandProp>`
  margin: 0;
  font: ${brandFont.copyTwo};
  ${BrandColor}
`;

export const ModalDescription = styled.p<IsFHSBrandProp>`
  margin-block-start: 0;
  margin-block-end: 1.5rem;
  margin-inline: 0;
  font: ${brandFont.copyOne};
  ${BrandColor}
`;

export const IconRefillDrink = styled(IconRefillDrinkBase)`
  ${IconMarginStyles}
`;
export const IconQRCodeScanner = styled(IconQrCodeScannerBase)`
  ${IconMarginStyles}
`;

export const QRCodeWrapper = styled.div`
  & > canvas {
    border: 1px solid ${primitive.$blackOpacity10};
    border-radius: 8px;
  }
`;
