import React from 'react';

import { useLayoutContext } from 'components/layout/layout-context';

import { Footer as FooterContent } from './footer';

export const Footer = () => {
  const { showFooter } = useLayoutContext();
  return showFooter ? <FooterContent /> : null;
};
