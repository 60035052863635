import React, { SVGProps } from 'react';

export const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.5 17.5V11.5H12.5V17.5H17.5V9.5H20.5L10.5 0.5L0.5 9.5H3.5V17.5H8.5Z" />
  </svg>
);
