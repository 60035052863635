import { useCallback, useContext, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { useGetUserOrdersLazyQuery as useGetUserOrdersLazyQueryFulfillment } from 'generated/graphql-gateway';
import { RbiOrderStatus, useGetUserOrdersLazyQuery } from 'generated/rbi-graphql';
import { IWaitingPaymentInformation } from 'pages/cart/payment/order-payment/waiting-payment';
import { AuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LocalStorage, StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing/routes';

export const useForceWaitingPaymentRedirects = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const authenticated = isAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();

  const isFulfillmentServiceEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_FULFILLMENT_SERVICE_GET_USER_ORDERS
  );

  const variables = {
    orderStatuses: [RbiOrderStatus.PRICE_SUCCESSFUL],
  };

  const [getOrderFulfillment] = useGetUserOrdersLazyQueryFulfillment({
    variables,
  });
  const [getOrder] = useGetUserOrdersLazyQuery({ variables });

  const getPaymentRequestTimeLimit = (paymentRequestTimeLimit: number | undefined) =>
    paymentRequestTimeLimit ? paymentRequestTimeLimit : 0;

  const isTimestampExpired = (paymentRequestTimeLimit: number) =>
    Date.now() > paymentRequestTimeLimit;

  const fetchOrders = useCallback(async () => {
    const { data } = isFulfillmentServiceEnabled ? await getOrderFulfillment() : await getOrder();
    const order = data?.userOrders?.orders?.[0];

    const paymentRequestTimeLimit =
      parseInt(order?.cart.payment?.paymentRequestTimeLimit ?? '') || 0;
    const hasPaymentRequestTimeLimit = paymentRequestTimeLimit > 0;
    const orderIsPriceSuccessful = order?.status === RbiOrderStatus.PRICE_SUCCESSFUL;
    const timestampExpired = Date.now() > paymentRequestTimeLimit;

    const isOrderWaitingPayment = !!order && !!hasPaymentRequestTimeLimit && orderIsPriceSuccessful;

    if (!isOrderWaitingPayment || timestampExpired) {
      return;
    }

    const waitingPaymentParsed: IWaitingPaymentInformation = {
      amount: order?.cart.totalCents || 0,
      paymentMethod: order?.cart.payment?.paymentType || '',
      paymentRequestTimeLimit,
      rbiOrderId: order?.rbiOrderId || '',
    };
    LocalStorage.setItem(StorageKeys.WAITING_PAYMENT, waitingPaymentParsed);
    navigate(routes.waitingPayment, { replace: true });
  }, [getOrder, getOrderFulfillment, isFulfillmentServiceEnabled, navigate]);

  const getWaitingPaymentInfo = useCallback(() => {
    const localStorageWaitingPayment: IWaitingPaymentInformation | null = LocalStorage.getItem(
      StorageKeys.WAITING_PAYMENT
    );

    const paymentRequestTimeLimit = getPaymentRequestTimeLimit(
      localStorageWaitingPayment?.paymentRequestTimeLimit
    );
    const timestampExpired = isTimestampExpired(paymentRequestTimeLimit);

    return { localStorageWaitingPayment, timestampExpired };
  }, []);

  useEffect(() => {
    if (!authenticated) {
      return;
    }
    const { localStorageWaitingPayment, timestampExpired } = getWaitingPaymentInfo();

    const shouldIgnoreWaitingPayment = localStorageWaitingPayment && timestampExpired;
    const shouldRedirect = localStorageWaitingPayment && !timestampExpired;

    if (shouldIgnoreWaitingPayment) {
      return;
    }

    if (shouldRedirect) {
      navigate(routes.waitingPayment, { replace: true });
      return;
    }

    fetchOrders();
  }, [
    authenticated,
    fetchOrders,
    getOrder,
    getOrderFulfillment,
    getWaitingPaymentInfo,
    isFulfillmentServiceEnabled,
    navigate,
  ]);

  useEffect(() => {
    const url = location.pathname;
    const isWaitingPaymentPage = url === '/cart/payment/waiting';

    if (isWaitingPaymentPage) {
      return;
    }

    const { localStorageWaitingPayment, timestampExpired } = getWaitingPaymentInfo();

    const shouldRedirect = localStorageWaitingPayment && !timestampExpired;

    if (shouldRedirect) {
      navigate(routes.waitingPayment, { replace: true });
      return;
    }
  }, [navigate, location, getWaitingPaymentInfo]);

  useEffect(() => {
    const handlePopState = () => {
      const url = location.pathname;
      const isWaitingPaymentPage = url === '/cart/payment/waiting';

      if (isWaitingPaymentPage) {
        return;
      }

      const { localStorageWaitingPayment, timestampExpired } = getWaitingPaymentInfo();

      const shouldRedirect = localStorageWaitingPayment && !timestampExpired;

      if (shouldRedirect) {
        navigate(routes.waitingPayment, { replace: true });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [getWaitingPaymentInfo, location.pathname, navigate]);
};
