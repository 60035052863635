import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  height: 44px;
  width: 44px;
  padding: 8px;
`;

export const IconWrapper = styled.div`
  height: 32px;
  width: 32px;
`;
