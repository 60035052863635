import styled from 'styled-components';

import { DialogMemo } from 'components/dialog';
import { brandFont } from 'styles/brand-font';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonsContainer = styled(Container)`
  width: 100%;
`;

export const Text = styled.p`
  font: ${brandFont.copyOne};
  margin: 0;
  text-align: center;
`;

export const AddressContainer = styled(Container)`
  padding-block-start: 12px;
  padding-block-end: 24px;
  padding-inline: 24px;
`;

export const Title = styled.span`
  font: ${brandFont.headerOne};
  margin-block-start: 36px;
  text-align: center;
`;

export const CustomDialog = styled(DialogMemo)`
  max-width: 520px;
`;

export const PrimaryButtonWrapper = styled.div`
  width: 100%;
  margin-block-end: 12px;
`;
