import React from 'react';

import { groupBy } from 'lodash';

import {
  ICombo,
  IComboSlot,
  IComboSlotOption,
  IComboSlotSelection,
  IComboSlotSelections,
  IItem,
  IModifierSelection,
  IPicker,
  IPickerPromotion,
} from '@rbi-ctg/menu';
import { ProductProps } from 'components/product/types';
import {
  computeModifierSelectionLabel,
  getModifierType,
} from 'components/product-detail/modifier/utils';
import { StyledOverviewText } from 'components/product-detail/styled';
import { IProductListItemProps } from 'components/product-list-item/types';
import { computeProductCalories } from 'hooks/menu/utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  IModifierSelectionActionWithData,
  IUserSelection,
  UserSelectionModifierSlotMap,
} from 'state/product-wizard/types';
import { isCombo, isItem } from 'utils/menu';

import { GetProductPropsSelectionsFn } from './types';

export const transformItemToProductListItemProps = (
  item: IItem | IPicker,
  extras?: {
    productNote?: string;
    isExpandable?: boolean;
    selections?: ProductProps['selections'];
    price?: number;
  }
): IProductListItemProps => ({
  isClickable: extras?.isExpandable,
  productProps: {
    name: item.name.locale,
    calories: String(computeProductCalories(item as IItem)),
    image: item.image ? JSON.stringify(item.image) : undefined,
    isProductNameBold: true,
    note: extras?.productNote,
    selections: extras?.selections,
    price: extras?.price,
  },
});

export const getProductPropsSelections: GetProductPropsSelectionsFn = ({
  formatCalories,
  formatCurrencyForLocale,
  formatMessage,
  modifierSelections = {},
  priceForItemOptionModifier,
}) => {
  const productPropsSelections: NonNullable<ProductProps['selections']> = [];
  Object.entries(modifierSelections).forEach(([, selectionsMap]) => {
    Object.values(selectionsMap).forEach(({ option, modifier, isSanityDefaultSelection, item }) => {
      if (isSanityDefaultSelection || modifier.quantity <= 0) {
        return;
      }
      const hideUiCalories = useFlag(LaunchDarklyFlag.HIDE_UI_CALORIES);
      const enableHideModifierMultipliers = useFlag(
        LaunchDarklyFlag.ENABLE_HIDE_MODIFIER_MULTIPLIERS
      );
      const showLabel = !(enableHideModifierMultipliers && option.options.length === 1);
      const label = computeModifierSelectionLabel({
        modifierType: getModifierType(option),
        modifierOption: modifier,
        modifierName: option.name?.locale,
        formatMessage,
      });

      const modifierCalories = modifier.nutrition?.calories || 0;
      const caloriesLabel = `${formatCalories(Math.abs(modifierCalories))}`;

      const modifierPrice = priceForItemOptionModifier({
        item,
        itemOption: option,
        modifier,
      });
      const upcharge = modifierPrice > 0 ? `+${formatCurrencyForLocale(modifierPrice)}` : '';

      const element = (
        <span>
          {showLabel ? label : null}
          <StyledOverviewText>{!hideUiCalories && caloriesLabel}</StyledOverviewText>
          {upcharge && <b data-testid="modifier-upcharge">{upcharge}</b>}
        </span>
      );

      productPropsSelections.push({
        id: `${option._key}-${modifier._key}`,
        element,
      });
    });
  });
  return productPropsSelections;
};

export const getModifiersActions = (
  modifierSlotMap: UserSelectionModifierSlotMap
): IModifierSelectionActionWithData[] => {
  return Object.values(modifierSlotMap ?? {})
    .map(modifierActionsMap => Object.values(modifierActionsMap))
    .flat();
};

export const getComboSlotOptionFromItem = (
  comboSlot?: IComboSlot | null,
  selectedItem?: IItem | null
) => {
  return comboSlot?.options.find(opt => opt.option._id === selectedItem?._id);
};

/*
 * NOTE: The following transform utils are needed to covert to IComboSlotSelections + IModifierSelection type
 * These types are used in other helper methods such as - pricing, cart, nutrition, etc.
 * New wizard has a UserSelections type that is slightly different due to new designs
 * Action item - clean up/resolve the two different types so both can be supported outside wizard
 */

export const transformUserSelectionsComboToComboSlotSelections = (
  selections: IUserSelection['comboSlot'] | undefined,
  selectedProduct?: ICombo
): IComboSlotSelections => {
  if (!selections) {
    return {};
  }
  const legacySelections: IComboSlotSelections = {};
  const selectionsAsArrayWithoutSelectedItem = Array.from(selections.values()).filter(
    sel => sel.comboSlot && !sel.selectedItem
  );
  const selectionsAsArrayWithSelectedItem = Array.from(selections.values()).filter(
    sel => sel.comboSlot && sel.selectedItem
  );
  const selectionsGroupedByComboSlot = groupBy(
    selectionsAsArrayWithSelectedItem,
    sel => sel.comboSlot?._id
  );
  Object.entries(selectionsGroupedByComboSlot).forEach(([comboSlotId, value]) => {
    const itemsGroupedByItemId = groupBy(value, val => val.selectedItem?._id);
    const comboSlotOptionsSelections: IComboSlotSelection[] = Object.values(
      itemsGroupedByItemId
    ).map(values => ({
      option: getComboSlotOptionFromItem(
        values[0]?.comboSlot,
        values[0]?.selectedItem
      ) as IComboSlotOption,
      quantity: values.length,
    }));
    legacySelections[comboSlotId] = {
      data: value[0].comboSlot as IComboSlot,
      selections: comboSlotOptionsSelections,
    };
  });

  // When we have locked/collapsed combo slots
  if (selectionsAsArrayWithoutSelectedItem.length) {
    selectionsAsArrayWithoutSelectedItem.forEach(({ comboSlot }) => {
      const comboSlotId = comboSlot?._id;
      if (comboSlotId) {
        legacySelections[comboSlotId] = {
          data: comboSlot as IComboSlot,
          selections: (comboSlot?.options || []).map(opt => ({
            option: opt,
            quantity: opt.defaultAmount,
          })),
        };
      }
    });
  }

  // Include hidden slots that are omitted from IUserSelections state
  const hiddenSlots = (selectedProduct?.options || []).reduce((acc, slot) => {
    if (slot.uiPattern === 'hidden') {
      acc[slot._id] = {
        data: slot,
        selections: [
          {
            option: slot?.options[0],
            quantity: slot?.options.length,
          },
        ],
      };
    }
    return acc;
  }, {} as IComboSlotSelections);

  return {
    ...legacySelections,
    ...hiddenSlots,
  };
};

export const transformUserSelectionsModifierToModifierSelection = (
  selections: IUserSelection
): IModifierSelection[] =>
  Object.entries(selections.modifiers).flatMap(([modifierSlotKey, modifierSlotMap]) =>
    Object.values(modifierSlotMap).flatMap(modifierActionsMap =>
      Object.values(modifierActionsMap)
        .filter(({ modifier }) => modifier.quantity > 0)
        .map(({ itemId, modifier, option }) => ({
          _key: option._key,
          _type: option._type,
          comboSlotId: selections.comboSlot?.get(modifierSlotKey)?.comboSlot?._id,
          componentStyle: option.componentStyle,
          allowMultipleSelections: option.allowMultipleSelections,
          itemId,
          fifoPositions: [], // not being used in new wizard (first in first out concept)
          modifier,
          name: option.name,
          upsellModifier: option.upsellModifier,
          injectDefaultSelection: option.injectDefaultSelection,
        }))
    )
  );

/* END NOTE */

export const createPickerPromotionMap = (
  options: IPicker['options']
): { [key: string]: IPickerPromotion } =>
  (options || []).reduce(
    (acc, { pickerItemMappings, option }) => {
      if ((isItem(option) || isCombo(option)) && option.promotion && pickerItemMappings?.length) {
        const { promotion } = option;
        // using the latest mapping item to reach out the item/combo itself when the user finalize the selection
        const { pickerAspectValueIdentifier, pickerAspect: itemPickerAspect } =
          pickerItemMappings.slice(-1)[0];
        acc[`${itemPickerAspect._id}-${pickerAspectValueIdentifier}`] = promotion;
      }

      return acc;
    },
    {} as Record<string, IPickerPromotion>
  );

export const priceWithoutTax = (price: number, taxPercentage: number): number => {
  return Math.round(((100 - taxPercentage) / 100) * price);
};
