import { useCallback, useRef } from 'react';

type GoogleGeocode = google.maps.Geocoder;

export const usePlaceIdDetails = () => {
  const geocoder = useRef<GoogleGeocode>();

  const getDetails = useCallback(
    (placeId?: string): Promise<google.maps.GeocoderResult | void> =>
      new Promise(resolve => {
        if (!placeId) {
          resolve();
        } else {
          if (!geocoder.current) {
            geocoder.current = new window.google.maps.Geocoder();
          }

          geocoder.current?.geocode({ placeId }, (results, status) => {
            if (status !== window.google.maps.GeocoderStatus.OK) {
              resolve();
            } else {
              resolve(results?.[0]);
            }
          });
        }
      }),
    []
  );

  return getDetails;
};
