import React, { createContext, useContext } from 'react';

import { useIntl } from 'react-intl';

import { useLocationContext } from 'state/location';
import { TLocalizationKey } from 'types/i18n';
import { UUID_REGEX_STRING } from 'utils/constants';
import { routes } from 'utils/routing';

interface ILayoutContext {
  showFooter?: boolean;
  showHeaderDesktop?: boolean;
  showHeaderMobile?: boolean;
  showBottomTabs?: boolean;
}

const defaultContext: ILayoutContext = {
  showFooter: true,
  showHeaderDesktop: true,
  showHeaderMobile: true,
  showBottomTabs: true,
};

const LayoutContext = createContext<ILayoutContext>(defaultContext);

export const useLayoutContext = () => useContext(LayoutContext);

const routesLayoutConfig: Record<string, ILayoutContext> = {
  default: defaultContext,
  // User login flow
  [routes.signUp]: {
    showFooter: false,
    showBottomTabs: false,
    showHeaderDesktop: false,
  },
  [routes.signIn]: {
    showHeaderDesktop: false,
    showFooter: false,
  },
  [routes.confirmOtp]: {
    showFooter: false,
  },
  [routes.createAccount]: {
    showHeaderDesktop: false,
  },
  // Cart flow
  [routes.cart]: {
    showHeaderDesktop: false,
    showBottomTabs: false,
  },
  [routes.cartAddPaymentMethod]: {
    showBottomTabs: false,
    showHeaderDesktop: false,
    showHeaderMobile: false,
    showFooter: false,
  },
  // Store locator flow
  [routes.storeLocator]: {
    showHeaderMobile: false,
    showFooter: false,
  },
  'routes.storeLocator': {
    showHeaderMobile: false,
    showFooter: false,
  },
  // Order flow
  [routes.waitingPayment]: {
    showFooter: false,
    showBottomTabs: false,
    showHeaderMobile: false,
    showHeaderDesktop: false,
  },
  [`${routes.orderConfirmation}/${UUID_REGEX_STRING}`]: {
    showBottomTabs: false,
    showHeaderDesktop: false,
    showFooter: false,
  },
  [`${routes.orderProcessing}/${UUID_REGEX_STRING}`]: {
    showFooter: false,
  },
  [`${routes.orderPayment}/${UUID_REGEX_STRING}`]: {
    showFooter: false,
  },
  // Other flows
  [routes.membershipDetails]: {
    showHeaderMobile: false,
  },
  [routes.blueLightCard]: {
    showHeaderMobile: false,
  },
  [routes.loading]: {
    showFooter: false,
  },
  [routes.cateringBirthday]: {
    showFooter: false,
  },
  'routes.campaign': {
    showFooter: false,
  },
};

export const LayoutContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    location: { pathname },
  } = useLocationContext();
  const { formatMessage } = useIntl();

  const routeLayoutConfigKey =
    Object.keys(routesLayoutConfig).find(route =>
      pathname.match(
        route.startsWith('routes.') ? formatMessage({ id: route as TLocalizationKey }) : route
      )
    ) ?? 'default';

  const routeLayoutContext = {
    ...defaultContext,
    ...(routesLayoutConfig[routeLayoutConfigKey] ?? {}),
  };

  return <LayoutContext.Provider value={routeLayoutContext}>{children}</LayoutContext.Provider>;
};
