import React, { useCallback, useEffect, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { useSendUpdateUserAttributesEventMutation } from 'generated/graphql-gateway';
import { useCdpContext } from 'state/cdp';
import { updateMParticleConsentState } from 'state/cdp/mParticle/mparticle-consent-state';
import { CdpProviderTypes } from 'state/cdp/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { getConfigValue } from 'utils/environment';
import { LocalStorage, StorageKeys } from 'utils/local-storage';

import { useInitializeThirdPartyServices } from '../use-initialize-third-party-service';

import { isBranchUnavailable } from './isBranchUnavailable';
import { ConsentGroups, OneTrustConsentCategories } from './types';
import { useOneTrustSdk } from './use-one-trust-sdk';
import { getAllServicesInitialized, handleCdpConsent } from './utils';

export const OneTrustCookieBanner = () => {
  const { loaded: otSdkLoaded } = useOneTrustSdk();

  const [servicesLoaded, setServiceLoaded] = useState(false);
  const [otConsentGroups, setConsentGroups] = useState({} as ConsentGroups);
  const [consentGroupsSetInLocalStorage, setConsentGroupsSetInLocalStorage] = useState(false);
  const branchUnavailable = isBranchUnavailable();
  const cdpProvider = useFlag(LaunchDarklyFlag.CDP_PROVIDER);
  const enableMobileCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_MOBILE_COOKIE_BANNER);
  const { initServicesWithCookies } = useInitializeThirdPartyServices();
  const { trackEvent, updateUserAttributes } = useCdpContext();
  const [sendUpdateUserAttributesEvent] = useSendUpdateUserAttributesEventMutation();

  const getConsents = useCallback(() => {
    const { cookieCompliance } = getConfigValue('cookieBanners').oneTrust;
    const {
      globalConsentState,
      consentCategoryMParticle,
      consentCategoryBraze,
      consentCategoryBranch,
      consentCategoryDdRum,
    } = cookieCompliance;

    // reads consent state for each consent category
    const cdpConsentState = otConsentGroups[consentCategoryMParticle as keyof ConsentGroups];
    const brazeConsentState = otConsentGroups[consentCategoryBraze as keyof ConsentGroups];
    const branchConsentState = otConsentGroups[consentCategoryBranch as keyof ConsentGroups];
    const datadogRumConsentState = otConsentGroups[consentCategoryDdRum as keyof ConsentGroups];
    const isCdpStrictlyNecessary = consentCategoryMParticle === OneTrustConsentCategories.C0001;

    return {
      globalConsentState,
      cdpConsentState,
      brazeConsentState,
      branchConsentState,
      datadogRumConsentState,
      isCdpStrictlyNecessary,
    };
  }, [otConsentGroups]);

  useEffect(() => {
    if (otSdkLoaded && !servicesLoaded) {
      initServicesWithCookies();
    }
  }, [initServicesWithCookies, otSdkLoaded, servicesLoaded]);

  const servicesInitCheck = useCallback(() => {
    const branchValidation = branchUnavailable || !!window.branch;
    const areServicesInitialized = getAllServicesInitialized(
      cdpProvider as CdpProviderTypes,
      branchValidation
    );
    if (areServicesInitialized) {
      setServiceLoaded(true);
    }
    return areServicesInitialized;
  }, [branchUnavailable, cdpProvider]);

  // Sets a interval to check if all 3rd party services are initialized
  useEffect(() => {
    const id = setInterval(servicesInitCheck, 1000);
    if (servicesLoaded) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [servicesInitCheck, servicesLoaded]);

  const getConsentGroups = (consentState: String[]) => {
    const groups: ConsentGroups = {
      C0001: 0,
      C0002: 0,
      C0003: 0,
      C0004: 0,
    };

    return Object.keys(groups).reduce((accumulator, group) => {
      if (consentState.includes(group)) {
        accumulator[group as keyof ConsentGroups] = 1;
      }
      return accumulator;
    }, groups);
  };

  const getConsentGroupByName = (consentState: ConsentGroups) => {
    const groups: { [key: string]: string } = {
      C0001: 'strictly_necessary',
      C0002: 'performance',
      C0003: 'functional',
      C0004: 'targeting',
    } as const satisfies Record<OneTrustConsentCategories, string>;

    type TGroupValue = (typeof groups)[keyof typeof groups];
    const consent: Record<TGroupValue, boolean> = {
      strictly_necessary: false,
      performance: false,
      functional: false,
      targeting: false,
    };

    (Object.keys(consentState) as (keyof ConsentGroups)[]).forEach(key => {
      const newKey = groups[key];
      consent[newKey] = consentState[key] === 0 ? false : true;
    });

    return consent;
  };

  // Registers onetrust consent change handler
  useEffect(() => {
    if (!otSdkLoaded || consentGroupsSetInLocalStorage) {
      return;
    } // Prevent re-runs

    const id = setInterval(() => {
      if (window?.OneTrust) {
        clearInterval(id); // Stops the interval immediately after first execution

        window.OneTrust.OnConsentChanged((event: any) => {
          const consentState = event?.detail ?? [];
          const consentGroups = getConsentGroups(consentState);
          const consentGroupsByName = getConsentGroupByName(consentGroups);

          const {
            globalConsentState,
            cdpConsentState,
            brazeConsentState,
            branchConsentState,
            isCdpStrictlyNecessary,
          } = getConsents();

          handleCdpConsent(cdpProvider as CdpProviderTypes, {
            cdpConsentState,
            brazeConsentState,
            branchConsentState,
            globalConsentState,
            branchUnavailable,
            isStrictlyNecessary: isCdpStrictlyNecessary,
            otConsentGroups: consentGroupsByName,
            trackEvent,
          });

          if (cdpProvider === CdpProviderTypes.Amplitude) {
            updateUserAttributes(consentGroupsByName, {}, sendUpdateUserAttributesEvent);
          }

          setConsentGroups(consentGroups);
          if (enableMobileCookieBanner) {
            updateMParticleConsentState({ consentGroups });
          }
          LocalStorage.setItem(StorageKeys.ONETRUST_COOKIE_CONSENT_GROUPS, consentGroups);
        });
        setConsentGroupsSetInLocalStorage(true);
      }
    }, 1000);
    return () => clearInterval(id); // Cleanup on unmount
  }, [otSdkLoaded]); // Ensures effect runs only when OneTrust loads

  // Pull the consent groups out of local storage if it's already populated.
  // This will trigger the hook below, and disable/enable any global services based on
  // the previously chosen settings.
  useEffect(() => {
    const existingConsentGroups = LocalStorage.getItem(StorageKeys.ONETRUST_COOKIE_CONSENT_GROUPS);
    if (existingConsentGroups) {
      setConsentGroups(existingConsentGroups);
    }
  }, []);

  // Switches off tracking for each service if data tracking is opted out
  useEffect(() => {
    // if consent state is opted out for respective service, data tracking is opted out
    if (servicesLoaded) {
      if (enableMobileCookieBanner) {
        updateMParticleConsentState({ consentGroups: otConsentGroups });
      }
      const { datadogRumConsentState } = getConsents();
      const shouldUseSessionReplay = datadogRumConsentState === 1;
      if (shouldUseSessionReplay) {
        datadogRum.startSessionReplayRecording();
        datadogRum.startView();
      } else {
        datadogRum.stopSessionReplayRecording();
        datadogRum.stopSession();
      }
    }
  }, [otConsentGroups, servicesLoaded, enableMobileCookieBanner, getConsents]);

  return <div data-testid="one-trust-cookie-banner" />;
};

export const OneTrustCookieBannerMemo = React.memo(OneTrustCookieBanner);
