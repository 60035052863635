import React from 'react';

import { useLayoutContext } from 'components/layout/layout-context';
import { useMediaQuery } from 'hooks/use-media-query';

import { DesktopHeader } from './desktop-header';
import { MobileHeader } from './mobile-header';

export const AppHeader: React.FC = () => {
  const isMobile = useMediaQuery('headerMobile');
  const { showHeaderMobile, showHeaderDesktop } = useLayoutContext();
  const hideHeader = !(isMobile ? showHeaderMobile : showHeaderDesktop);

  if (hideHeader) {
    return null;
  }

  return isMobile ? <MobileHeader /> : <DesktopHeader />;
};
