import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { IUseDialogComponentProps } from 'hooks/use-dialog-modal';
import { useFeatureStaticMenu } from 'hooks/use-feature-static-menu';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStaticMenuContext } from 'state/static-menu';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { ModalItemUnavailableView } from './modal-item-unavailable.view';

/**
 *
 * This Modal appears if a the selected menu item from the static menu is not available in
 * their selected store or the nearest store that can deliver.
 *
 */
export const ModalItemUnavailable: FC<IUseDialogComponentProps> = ({ onDismiss }) => {
  const { itemUnavailableModal } = useFeatureStaticMenu();
  const { resetStore } = useStoreContext();
  const { selectedStaticMenuItemName } = useStaticMenuContext();
  const { formatMessage } = useIntl();
  const { serviceMode } = useServiceModeContext();
  const navigate = useNavigate();

  const imageAlt = `${formatMessage({ id: 'itemsUnavailable' })} ${formatMessage({ id: 'image' })}`;

  if (!itemUnavailableModal) {
    return null;
  }

  const {
    header,
    image,
    body: defaultBodyText,
    selectNew: selectNewBtnText,
    continue: continueBtnText,
    itemNotAvailableForDelivery,
  } = itemUnavailableModal;

  const handleContinue = () => {
    onDismiss();
    navigate(routes.menu, { replace: true });
  };

  const handleChangeStore = () => {
    onDismiss();
    resetStore();
  };

  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const body = isDelivery ? itemNotAvailableForDelivery : defaultBodyText;

  return (
    <ModalItemUnavailableView
      header={header}
      image={image}
      body={body}
      continueBtnText={continueBtnText}
      selectNewBtnText={selectNewBtnText}
      handleContinue={handleContinue}
      handleChangeStore={handleChangeStore}
      unavailableItemName={selectedStaticMenuItemName}
      imageAlt={imageAlt}
      hideSelectNewBtn={isDelivery}
    />
  );
};
