import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

import {
  ConfirmationActions,
  ConfirmationButton,
  ConfirmationContainer,
  ConfirmationDescription,
  ConfirmationHeader,
} from '../loyalty-cart-drawer.styled';

import { PaperBagIcon } from './icons/PaperBagIcon';

interface ConfirmationDrawerProps {
  setIsOpen: (value: boolean) => void;
}

export const ConfirmationDrawer: FC<ConfirmationDrawerProps> = ({ setIsOpen }) => {
  const { formatMessage } = useIntl();
  const { navigate, location } = useLocationContext();
  const { trackEvent } = useCdpContext();

  const enableLoyaltyInApps = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_IN_APPS);

  useEffectOnce(() => {
    trackEvent({
      name: CustomEventNames.ACTION_SHEET_VIEW,
      type: EventTypes.Other,
      attributes: {
        Message: 'Loyalty Transaction Created',
        'Source Page': location.pathname,
      },
    });
  });

  return (
    <ConfirmationContainer>
      <ConfirmationHeader>
        {formatMessage({ id: 'loyaltyScannedSuccessfullyTitle' })}
      </ConfirmationHeader>
      <ConfirmationDescription>
        {formatMessage({ id: 'loyaltyScannedSuccessfullyDescription' })}
      </ConfirmationDescription>
      <PaperBagIcon />
      <ConfirmationActions>
        {enableLoyaltyInApps && (
          <ConfirmationButton
            variant={ActionButtonVariants.PRIMARY}
            data-testid="confirmation-drawer-add-more-rewards"
            size={ActionButtonSizes.LARGE}
            perceptible
            onClick={() => {
              setIsOpen(false);
              navigate(routes.rewardsList);
            }}
          >
            {formatMessage({ id: 'loyaltyAddMoreRewards' })}
          </ConfirmationButton>
        )}
        <ConfirmationButton
          variant={ActionButtonVariants.OUTLINE}
          data-testid="confirmation-drawer-homepage"
          size={ActionButtonSizes.LARGE}
          perceptible
          onClick={() => {
            setIsOpen(false);
            navigate(routes.base);
          }}
        >
          {formatMessage({ id: 'loyaltyConfirmationGoToHome' })}
        </ConfirmationButton>
      </ConfirmationActions>
    </ConfirmationContainer>
  );
};
