import styled from 'styled-components';

import { brandFont } from 'styles/brand-font';
import { primitive } from 'styles/constants/primitives';

export const PriceAndCaloriesText = styled.p`
  margin: 0;
  padding: 0;
  font: ${brandFont.copyTwo};
`;

export const Price = styled.span`
  font-weight: bold;
  margin-inline-start: ${primitive.$spacing2};
`;
