import styled from 'styled-components';

import { brandFont } from 'styles/brand-font';
import { primitive } from 'styles/constants/primitives';

export const Group = styled.div`
  background-color: ${Styles.color.cardBackground};
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`;

export const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${p => p.theme.token('padding-list-item-group-header')};
`;

export const HeaderText = styled.h3<{ $isNested?: boolean }>`
  font: ${p => (p.$isNested ? brandFont.headerFour : brandFont.headerThree)};
  margin: 0;
  width: 75%;
`;

export const SubHeaderText = styled.p`
  padding: ${primitive.$spacing1} ${primitive.$spacing4};
  font: ${brandFont.copyTwo};
  margin-block-start: -${primitive.$spacing2};
  margin-block-end: 0;
  margin-inline: 0;
`;

export const Footer = styled.div`
  border-block-end: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 1rem;
  min-height: 2.5rem;
  padding: ${primitive.$spacing1} ${primitive.$spacing4};
`;
