import React, { ReactNode, RefObject, memo } from 'react';

import { normalizedTranslate } from '@rbilabs/components-library';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IconClose } from 'components/icons/close';
import { OfferDisclaimer } from 'components/offers/ui-refresh/offer-disclaimer';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LoyaltyOffer } from 'state/loyalty/types';

import { theme } from './theme';

const CloseButton = styled.button`
  position: absolute;
  inset-block-start: 0.3rem;
  inset-inline-start: 0.3rem;
  background-color: transparent;
  border: none;
  padding: 0;
  height: 3rem;
  width: 3rem;

  &:hover {
    cursor: pointer;
  }

  svg {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    height: 2.25rem;
    transform: ${normalizedTranslate('-50%', '-50%')};
    fill: ${Styles.color.black};
  }
`;

export const ModalContainer = styled(DialogOverlay)`
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  inset-inline-start: 0;
  position: fixed;
  inset-block-start: 0;
  width: 100%;
  /* TODO: refactor z-indexes */
  z-index: ${Styles.zIndex.max - 200};
  display: flex;
  justify-content: center;
  align-content: center;

  ${Styles.mobile} {
    padding-block-start: env(safe-area-inset-top);
    padding-block-end: env(safe-area-inset-bottom);
    padding-inline-start: env(safe-area-inset-right);
    padding-inline-end: env(safe-area-inset-left);
  }

  [data-reach-dialog-content] {
    background: ${Styles.color.dialogBackground};
    border-radius: 20px;
    position: relative;
    margin: auto;
  }
`;

const showCloseButtonCss = css`
  padding-block-start: 3rem;
  padding-block-end: 1.5rem;
  padding-inline: 3rem;
`;

const nonShowCloseButtonCss = css`
  padding: 1.5rem;
`;

export const InnerContent = styled('div')<{ showCloseButton: boolean }>`
  ${p => (p.showCloseButton ? showCloseButtonCss : nonShowCloseButtonCss)}
  max-width: 90vw;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${Styles.desktop} {
    flex-direction: row;
  }
`;

export const Heading = styled.h2`
  font-family: ${Styles.fontFamily.base};
  text-transform: ${Styles.textTransform.headlines};
  text-align: center;
  line-height: 1.15;
  max-width: 30rem;
  margin: 0 auto;
`;

export const Body = styled.p`
  font-family: ${Styles.fontFamily.body};
  text-align: center;

  ${Styles.desktop} {
    max-width: 23rem;
    margin: 0 auto;
    padding: 0.5rem;
  }
`;

export const Disclaimer = styled.div`
  text-align: center;
  color: ${theme.disclaimerColor};
  font-style: normal;
  font-size: 0.75rem;
  max-width: ${Styles.layout.smallerSectionMaxWidth};
  margin-block-start: 2rem;
  margin-block-end: 0;
  margin-inline: auto;
  font-family: ${Styles.fontFamily.body};
`;

export interface IDialogProps extends IBaseProps {
  heading?: ReactNode;
  headingComponent?: ReactNode;
  body?: ReactNode;
  bodyComponent?: ReactNode;
  onDismiss?: VoidFunction;
  offer?: LoyaltyOffer | null;
  actions?: ReactNode;
  image?: ReactNode;
  disclaimer?: ReactNode;
  showCloseButton?: boolean;
  modalAppearanceEventMessage?: string;
  errorEventMessage?: string;
  initialFocusRef?: RefObject<any>;
  forceUpdatePriority?: string;
}

export const Dialog = ({
  className,
  heading,
  headingComponent,
  body,
  bodyComponent,
  onDismiss,
  offer,
  actions,
  disclaimer,
  image,
  initialFocusRef,
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
  /**
   * Additional Error details to be sent to mParticle.
   */
  errorEventMessage,
  'data-testid': dataTestId,
  showCloseButton = false,
}: IDialogProps) => {
  const { pathname } = useLocation();
  const { trackEvent } = useCdpContext();

  useEffectOnce(() => {
    trackEvent({
      name: CustomEventNames.MODAL_APPEARANCE,
      type: EventTypes.Other,
      attributes: {
        Message: modalAppearanceEventMessage,
        ErrorMessage: errorEventMessage,
        ModalHeader: typeof heading === 'string' ? heading : '',
        ModalMessage: body || '',
        'Source Page': pathname,
      },
    });
  });

  const { formatMessage } = useIntl();

  return (
    <ModalContainer isOpen onDismiss={onDismiss} initialFocusRef={initialFocusRef}>
      <DialogContent data-testid="overlay" aria-labelledby="modal-text" className={className}>
        {image}
        <InnerContent data-testid={dataTestId} showCloseButton={showCloseButton}>
          {showCloseButton && onDismiss && (
            <CloseButton
              aria-label={formatMessage({ id: 'closeDialog' })}
              onClick={onDismiss}
              data-testid="close-button"
            >
              <IconClose />
            </CloseButton>
          )}

          <div id="modal-text">
            {headingComponent || <Heading data-testid="dialog-heading">{heading}</Heading>}

            {bodyComponent || <Body aria-live="polite">{body}</Body>}
          </div>

          {actions && <Actions>{actions}</Actions>}
          {offer && <OfferDisclaimer offer={offer} />}
          {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
        </InnerContent>
      </DialogContent>
    </ModalContainer>
  );
};

export const DialogMemo = memo(Dialog);
