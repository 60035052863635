import { isNumber } from 'lodash';
import { NavigateFunction, NavigateOptions, To, useNavigate } from 'react-router';

import { isExternalLink } from 'utils/is-external-link';
import { createRedirectHandler } from 'utils/routing';

export const useEnhancedNavigate = (basename?: string) => {
  const navigate = useNavigate();

  const externalRedirect = createRedirectHandler(basename);

  const enhancedNavigate: NavigateFunction = (to: To | number, options?: NavigateOptions) => {
    if (isExternalLink(to)) {
      externalRedirect(to as string);
      return;
    }

    if (isNumber(to)) {
      navigate(to);
      return;
    }

    navigate(to, options);
  };

  return enhancedNavigate;
};
