import { useMemo } from 'react';

import { useLocation } from 'react-router';

import { useMediaQuery } from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  isSectionRoute,
  routes,
  routesForBottomServiceModeDisplay,
  routesForTopServiceModeDisplay,
} from 'utils/routing';

export const useRoutesDisplayBottomService = (): boolean => {
  const enableFixedAddToOrderAndQuantityButton = useFlag(
    LaunchDarklyFlag.ENABLE_FIXED_ADD_TO_ORDER_AND_QUANTITY_BUTTON
  );
  const enableTopServiceMode = useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE);

  const routesForServiceMode = enableTopServiceMode
    ? routesForTopServiceModeDisplay
    : routesForBottomServiceModeDisplay;

  const isDesktop = useMediaQuery('desktop');
  const location = useLocation();
  const showServiceModeHeader = useMemo(
    () =>
      routesForServiceMode.some(({ route, exact }) => {
        if (enableFixedAddToOrderAndQuantityButton && !isDesktop && route === routes.menu) {
          // when not on desktop, only show BSM on base menu or section routes, not item / combo / picker
          return location.pathname === routes.menu || isSectionRoute(location.pathname);
        }
        if (exact) {
          return location.pathname === route;
        }
        return location.pathname.startsWith(route);
      }),
    [enableFixedAddToOrderAndQuantityButton, isDesktop, location.pathname, routesForServiceMode]
  );
  return showServiceModeHeader;
};
