import React, { FC } from 'react';

import { Icon } from '@rbilabs/components-library';
import Carousel from 'nuka-carousel';
import { useIntl } from 'react-intl';

import { IWidgetElement } from 'pages/loyalty/loyalty-widget-components/types';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useLocationContext } from 'state/location';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';

import {
  Container,
  HowToEarnCarouselItem,
  HowToEarnContainer,
  HowToEarnDescription,
  HowToEarnStep,
  HowToEarnText,
  HowToEarnTitle,
  InfoBackButton,
  StyledCrownIcon,
  StyledFriesIcon,
} from '../loyalty-cart-drawer.styled';

import { KioskIcon } from './icons/KioskIcon';
import { InRestaurantScreen } from './types';

interface QRCodeDrawerProps {
  setDrawerType: (type: InRestaurantScreen) => void;
  widgets: Array<IWidgetElement>;
}

export const QRCodeDrawer: FC<QRCodeDrawerProps> = ({ setDrawerType, widgets }) => {
  const { formatMessage } = useIntl();
  const { location } = useLocationContext();
  const { trackEvent } = useCdpContext();
  const { inRestaurantRedemptionCart } = useInRestaurantRedemptionContext();

  return (
    <>
      {inRestaurantRedemptionCart.length ? (
        <InfoBackButton
          data-testid="how-to-use-loyalty-back-button"
          onClick={() => {
            trackEvent({
              name: CustomEventNames.BUTTON_CLICK,
              type: EventTypes.Other,
              attributes: {
                Name: 'Back',
                Path: location.pathname,
              },
            });
            setDrawerType(InRestaurantScreen.CART_LIST);
          }}
        >
          <Icon icon="back" color="icon-default" width="24px" aria-hidden />
        </InfoBackButton>
      ) : null}
      <HowToEarnContainer>
        <HowToEarnTitle>
          {formatMessage({ id: 'yourInRestaurantCartInfoPageTitle' })}
        </HowToEarnTitle>
        <Carousel
          slidesToShow={1}
          wrapAround={true}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
        >
          <HowToEarnCarouselItem>
            <HowToEarnText>
              <HowToEarnStep step={1} />
              <HowToEarnDescription>
                {formatMessage({ id: 'yourInRestaurantCartInfoPageStepOne' })}
              </HowToEarnDescription>
            </HowToEarnText>
            <KioskIcon />
          </HowToEarnCarouselItem>
          <HowToEarnCarouselItem>
            <HowToEarnText>
              <HowToEarnStep step={2} />
              <HowToEarnDescription>
                {formatMessage({ id: 'yourInRestaurantCartInfoPageStepTwo' })}
              </HowToEarnDescription>
            </HowToEarnText>
            <StyledCrownIcon />
          </HowToEarnCarouselItem>
          <HowToEarnCarouselItem>
            <HowToEarnText>
              <HowToEarnStep step={3} />
              <HowToEarnDescription>
                {formatMessage({ id: 'yourInRestaurantCartInfoPageStepThree' })}
              </HowToEarnDescription>
            </HowToEarnText>
            <StyledFriesIcon />
          </HowToEarnCarouselItem>
        </Carousel>
      </HowToEarnContainer>

      <Container>{widgets.map(({ element }) => element)}</Container>
    </>
  );
};
