import { useCallback, useEffect, useRef, useState } from 'react';

import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { getConfigValue, isIOS } from 'utils/environment';

declare global {
  interface Window {
    OneTrust: any;
    OptanonWrapper: any;
  }
}

type ErrorState = ErrorEvent | null;

const IS_BROWSER = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const ONE_TRUST_SDK_SCRIPT_ID = 'one-trust-cookie-consent-sdk';

/**
 * Loads one trust sdk cookie consent script
 */
export const useOneTrustSdk = () => {
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<ErrorState>(null);
  const { trackEvent } = useCdpContext();
  const enableMobileCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_MOBILE_COOKIE_BANNER);
  const shouldFixMetaTag = isIOS() && enableMobileCookieBanner;

  const handleEventOT = useCallback(
    (e: Event) => {
      e.stopPropagation();
      const button = e.target;
      if (!button) {
        return;
      }
      const buttonText = (button as HTMLButtonElement).innerText;
      setTimeout(() => {
        trackEvent({
          name: CustomEventNames.BUTTON_CLICK,
          type: EventTypes.Other,
          attributes: {
            Name: buttonText,
          },
        });
      }, 1000);
    },
    [trackEvent]
  );

  const acceptAllCookiesButton = useRef<HTMLElement | null>(null);
  const rejectAllButton = useRef<HTMLElement | null>(null);
  const allowAllButton = useRef<HTMLElement | null>(null);
  const rejectAllButtons = useRef<Element | null>(null);
  const confirmMyChoicesButtons = useRef<Element | null>(null);

  useEffect(() => {
    if (!loading && loaded) {
      return;
    }
    const { cookieCompliance } = getConfigValue('cookieBanners').oneTrust;
    const { scriptUrl, scriptId } = cookieCompliance;

    if (!IS_BROWSER || !scriptUrl || !scriptId) {
      return;
    }

    const existingScript = Array.from(document.querySelectorAll('script')).find(s =>
      s.src.includes('cookielaw.org/scripttemplates')
    );

    if (existingScript) {
      setLoading(false);
      setLoaded(true);
      return;
    }

    const handleLoad = () => {
      setLoading(false);
      setLoaded(true);
      // OptanonWrapper function is required by One Trust script which is called after the SDK is loaded
      window.OptanonWrapper = function () {
        // This hack is not good, but it is required if we want to add OT banner in mobile apps
        // in iOS. This logic fix an issue with the content of the webview over the notch and
        // safe area in iOS devices.
        if (shouldFixMetaTag) {
          document
            .querySelector('meta[name="viewport"]')
            ?.setAttribute(
              'content',
              'width=device-width,initial-scale=1,viewport-fit=cover,minimum-scale=1,maximum-scale=1'
            );
        }
        acceptAllCookiesButton.current = document.getElementById('onetrust-accept-btn-handler');
        rejectAllButton.current = document.getElementById('onetrust-reject-all-handler');
        allowAllButton.current = document.getElementById('accept-recommended-btn-handler');
        rejectAllButtons.current = document.getElementsByClassName('ot-pc-refuse-all-handler')[0];
        confirmMyChoicesButtons.current = document.getElementsByClassName(
          'save-preference-btn-handler onetrust-close-btn-handler'
        )[0];

        acceptAllCookiesButton.current?.addEventListener('click', handleEventOT);
        rejectAllButton.current?.addEventListener('click', handleEventOT);
        allowAllButton.current?.addEventListener('click', handleEventOT);
        rejectAllButtons.current?.addEventListener('click', handleEventOT);
        confirmMyChoicesButtons.current?.addEventListener('click', handleEventOT);
      };
    };

    const handleError = (err: ErrorState) => {
      setLoading(false);
      setLoaded(false);
      setError(err);
    };

    const otSdkScript = document.createElement('script');

    otSdkScript.setAttribute('src', scriptUrl);
    otSdkScript.setAttribute('data-domain-script', scriptId);
    otSdkScript.setAttribute('id', ONE_TRUST_SDK_SCRIPT_ID);
    otSdkScript.setAttribute('type', 'text/javascript');
    otSdkScript.setAttribute('charset', 'UTF-8');
    otSdkScript.addEventListener('load', handleLoad);
    otSdkScript.addEventListener('error', handleError);

    document.head.appendChild(otSdkScript);

    return () => {
      document.head.removeChild(otSdkScript);
      otSdkScript.removeEventListener('load', handleLoad);
      otSdkScript.removeEventListener('error', handleError);
      acceptAllCookiesButton.current?.removeEventListener('click', handleEventOT);
      rejectAllButton.current?.removeEventListener('click', handleEventOT);
      allowAllButton.current?.removeEventListener('click', handleEventOT);
      rejectAllButtons.current?.removeEventListener('click', handleEventOT);
      confirmMyChoicesButtons.current?.removeEventListener('click', handleEventOT);
    };
  }, []);

  return { loaded, loading, error };
};
