import React, { FC } from 'react';

import { useAccessibility } from 'state/accessibility';
import { routes } from 'utils/routing';

import { IconWrap, NavLinkText, NavLinkWithIcon } from './nav-link-icon.styled';

type NavLinkIconProps = {
  label: string;
  icon: React.ReactNode;
  route: string;
  forceExactMatch?: boolean;
};

export const NavLinkIcon: FC<NavLinkIconProps> = ({
  label,
  icon,
  route,
  forceExactMatch = false,
}) => {
  const requiresExactMatch = forceExactMatch || route === routes.base || route === routes.account;
  const { isFullPageModalActive } = useAccessibility();

  return (
    <NavLinkWithIcon
      to={route}
      requiresExactMatch={requiresExactMatch}
      hidden={isFullPageModalActive}
      data-testid="nav-link-icon"
      navBarText={label}
      componentKey={label}
    >
      <IconWrap>{icon}</IconWrap>
      <NavLinkText aria-hidden={isFullPageModalActive}>{label}</NavLinkText>
    </NavLinkWithIcon>
  );
};
