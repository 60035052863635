import { Link } from 'react-router';
import styled from 'styled-components';

export { DesktopSignupBanner } from './base';

export const ButtonSubText = styled.div`
  font-size: 0.7rem;
  text-transform: none;
`;

export const Heading = styled.h2`
  color: ${Styles.color.black};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: center;
  font-family: ${Styles.fontFamily.base};
  font-size: 2.25rem;
`;

export const ButtonWrapper = styled.div`
  margin: 1rem 0;
`;

export const NewLayoutContainer = styled.div`
  padding: 1rem;
  margin: 0 auto;
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
`;

export const NewLayoutContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  color: ${Styles.color.primary};
  text-decoration: none;
  text-decoration: underline;
  &:hover {
    color: ${Styles.color.primaryHover};
  }
`;
