import React from 'react';

import { LinkProps, useMatch } from 'react-router';

import { isExternalLink } from 'utils/is-external-link';

import { StyledLink } from './styled';

interface INavLink extends LinkProps {
  matchRoute?: string;
  to: string;
}

export const NavLink: React.FC<INavLink> = ({ matchRoute, ...rest }) => {
  const routeToMatch = matchRoute ?? rest.to;
  const match = useMatch(routeToMatch);
  const target = isExternalLink(rest.to) ? '_blank' : undefined;

  return <StyledLink target={target} className={match ? 'selected' : ''} {...rest} />;
};
