import React from 'react';

import styled from 'styled-components';

import { Wordmark } from 'components/wordmark';

import { FooterLanguageSelector } from './footer-language-selector';
import { SocialIcons } from './social-icons';
import { WordmarkSocialIconsContainer } from './styled';
import { IWordmarkAndSocialIcons as IWordmarkAndSocialIconsProps } from './types';

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Styles.desktop} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const StyledWordmark = styled(Wordmark)`
  margin: 2rem 0;

  ${Styles.desktop} {
    margin: 0;
  }
`;

const SecondaryContentContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const WordmarkAndSocialIcons: React.FC<IWordmarkAndSocialIconsProps> = ({
  socialIconsWidget,
}) => {
  const socialIconsProps = {
    instagramUrl: socialIconsWidget?.instagramUrl?.locale,
    facebookUrl: socialIconsWidget?.facebookUrl?.locale,
    youtubeUrl: socialIconsWidget?.youtubeUrl?.locale,
    twitterUrl: socialIconsWidget?.twitterUrl?.locale,
    tiktokUrl: socialIconsWidget?.tiktokUrl?.locale,
    linkedinUrl: socialIconsWidget?.linkedinUrl?.locale,
    snapchatUrl: socialIconsWidget?.snapchatUrl?.locale,
  };

  return (
    <WordmarkSocialIconsContainer>
      <StyledWordmark aria-hidden="true" />
      <SecondaryContentContainer>
        <FooterLanguageSelector />
        <SocialIcons {...socialIconsProps} />
      </SecondaryContentContainer>
    </WordmarkSocialIconsContainer>
  );
};
